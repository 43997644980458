import { useDeferredValue, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import moment from 'moment'

import { useMutation, useQuery } from "@apollo/client";
import { ADD_LEAD, GET_COURSES, GET_LEADS, GET_USERS, UPSERT_LEAD } from "../utils/graphql-operation";

import { Button, ConfigProvider, DatePicker, Divider, Drawer, message, Popconfirm, Popover, Select, Space, Statistic, Table } from 'antd';
import { DeleteTwoTone, EditTwoTone, FieldTimeOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import _ from "lodash"
import {v4 as uuidv4} from "uuid"

import { ResponsivePie } from '@nivo/pie'

import invert from "invert-color"
import { useOutletContext } from "react-router-dom";

// import whiteLogo from "../logo-white.png"
// import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Option } = Select;

const MainLead = () => {

    const { setCurntMenu } = useOutletContext();

    const [modalShow, setModalShow] = useState(false)
    const [modalUpdate, setModalUpdate] = useState(false)
    // const [openPopover, setOpenPopover] = useState(false);
    // const [confirmLoading, setConfirmLoading] = useState(false);
    const [deleteRecordDetails, setDeleteRecordDetails] = useState({})
    const [columns, setColumns] = useState(
        [
            {
                title:"Date",
                dataIndex: "date",
                key: "date",
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
                sortDirections: ['descend'],
                render: (_, record) => {
                    return <>{moment(record.date).format("DD-MM-YYYY")}</>
                },
                width:150
            },
            {
                title:"Name",
                dataIndex: "name",
                key: "name",
                width: 250,
            },
            {
                title:"Phone Number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
                filters: [],
                filterSearch: true,
                onFilter: (value, record) => record.phoneNumber.indexOf(value) === 0,
                width: 180,
            },
            {
                title:"Lead Source",
                dataIndex: "leadSource",
                key: "leadSource",
                filters: [
                    {value:"YouTube",text:"YouTube"},
                    {value:"Facebook",text:"Facebook"},
                    {value:"Direct Call",text:"Direct Call"}
                ],
                filterSearch: true,
                onFilter: (value, record) => record.leadSource.indexOf(value) === 0,
                width: 180,
            },
            {
                title: "Course Enquired",
                dataIndex: "courseEnq",
                key: "courseEnq",
                width: 180,
                render: (_, record) => {
                    return <>{record.courseEnq?.courseName}</>
                }
            },
            {
                title: "Lead Status",
                dataIndex: "leadStat",
                key: "leadStat",
                width: 150,
                render: (_, record) => {
                    return <Space>{record.leadStat}<Popover content={
                        <>
                            {
                                record.leadStat === "Converted" ? "N/A" : record.leadStat === "Not Interested" ? "Follow up later" : record.leadStat === "Interested" ? "Follow Up via Message" : record.leadStat === "Not Connected" ? "Call Again" : record.leadStat === "Busy" ? "Call Again" : "N/A"
                            }
                        </>
                    } title="Second Follow Up Procedure"><InfoCircleTwoTone /></Popover></Space>
                },
                filters: [
                    {text: "Converted", value: "Converted"},
                    {text: "Not Interested", value: "Not Interested"},
                    {text: "Interested", value: "Interested"},
                    {text: "Not Connected", value: "Not Connected"},
                    {text: "Busy", value: "Busy"},
                ],
                onFilter: (value, record) => record.leadStat.indexOf(value) === 0,
            },
            {
                title: "Lead Type",
                dataIndex: "leadType",
                key: "leadType",
                filters: [
                    {value:"Cold",text:"Cold"},
                    {value:"Warm",text:"Warm"},
                    {value:"Hot",text:"Hot"},
                    {value:"N/A",text:"N/A"}
                ],
                filterSearch: true,
                onFilter: (value, record) => record.leadType.indexOf(value) === 0,
                width: 150
            },
            {
                title:"Assigned To",
                dataIndex: "assignedTo",
                key: "assignedTo",
                width: 250,
                filters: [],
                filterSearch: true,
                onFilter: (value, record) => record.assignedTo.uid.indexOf(value) === 0,
                render: (_, record) => {
                    return <>{record.assignedTo.userName}</>
                }
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (_, record) => (
                    <Space size="middle">
                            <Button type="link" icon={<EditTwoTone />} onClick={() => getRecord(record)} />
                            {/* <Popconfirm title="Are you sure you want to delete this lead" open={openPopover} onConfirm={handleOk} okButtonProps={{ loading: confirmLoading }} onCancel={handleCancel}> */}
                            { !record.trainerTouch ? <Popconfirm title="Are you sure you want to delete this lead" onConfirm={handleOk}>
                                <Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(record)}></Button>
                            </Popconfirm> : ""}
                        </Space>
                ),
            },
        ]
    )

    const showPopconfirm = (record) => {
        setDeleteRecordDetails(record)
    };
    
    const handleOk = () => {
        // setConfirmLoading(true);
        upsertLead({
            variables:{
                query: {
                    lid: deleteRecordDetails.lid
                },
                data: {...deleteRecordDetails, active: false}
            }
        })
        .then(() => {
            message.success("Lead Deleted Successfully");
            refetch()
            setDeleteRecordDetails({})
            // setConfirmLoading(false);
        })
    };

    const [mainLeads, setMainLeads] = useState([])
    const [copyLeads, setCopyLeads] = useState([])

    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({
        defaultValues: {
            date: moment().toISOString(),
            name: "",
            phoneNumber: "",
            leadSource: "YouTube",
            assignedTo: "",
            timestamp: moment().valueOf(),
            uid: localStorage.getItem("uid"),
            lid: '',
            active: true,
            gender: "",
            nri: "",
            payment: [],
            balance: "",
            fees:"",
            course:"",
            batch:"",
            courseEnq:"",
            callStat:"",
            leadStat:"Not Connected",
            leadType:"N/A",
            remark:""
        },
    });
    const {data: userList}  = useQuery(GET_USERS,{
        variables: {
            query: {
                default_ne: true,
                active: true,
                role:{roleName: "Tutor"}
            }
        }
    })

    useMemo(()=>{
        if(userList){
            setColumns(
                c => {
                    const fieldIndex = c.findIndex(el => { return el.key === "assignedTo" })
                    let filter = []
                    userList.users.forEach(el => filter.push({text: el.userName, value: el.uid}))
                    const columnToUpdate = [...c]
                    columnToUpdate[fieldIndex].filters = filter
                    return columnToUpdate
                }
            )
        }
    },[userList])

    console.log("is Admin Type => ",typeof localStorage.getItem("isAdmin") )
    const {data: leads, loading:leadLoading, refetch} = useQuery(GET_LEADS,{
        variables: {
            query: {
                active: true,
                AND:[
                    {
                        uid: localStorage.getItem("isAdmin") === "true" ? {uid_nin: ["",null]} : localStorage.getItem("uid")
                    },
                    {
                        uid: {active_ne: false}
                    }
                ]
            }
        }
    })

    const {data: courseList}  = useQuery(GET_COURSES,{variables:{query:{}}})

    useEffect(()=>{
        setCurntMenu('1')
        if(leads){
            const mainLeadsKey = leads.mainLeads.map((el,index) => {
                return {...el, key: (index)}
            })
            setMainLeads(mainLeadsKey)
            setColumns(
                c => {
                    const fieldIndex = c.findIndex(el => { return el.key === "phoneNumber" })
                    let filter = []
                    leads.mainLeads.forEach(el => filter.push({text: el.phoneNumber, value: el.phoneNumber}))
                    const columnToUpdate = [...c]
                    columnToUpdate[fieldIndex].filters = _.uniqWith(filter,_.isEqual)
                    return columnToUpdate
                }
            )
        }
    },[leads,setCurntMenu])

    useMemo(() => {
        setCopyLeads(mainLeads)
    },[mainLeads])

    const [disableFields, setDisableFields] = useState(false)

    const getRecord = (record) => {
        console.log("Record ====> ",record)
        reset()
        setValue("date", moment(record.date))
        setValue("name", record.name)
        setValue("phoneNumber", record.phoneNumber)
        setValue("leadSource", record.leadSource)
        setValue("assignedTo.uid", record.assignedTo.uid)
        setValue("lid", record.lid)
        setValue("courseEnq.cid",record.courseEnq ? record.courseEnq.cid : "")
        setValue("callStat",record.callStat)
        setValue("leadStat",record.leadStat)
        setValue("leadType",record.leadType)
        if(record.trainerTouch){
            setDisableFields(true)
        }
        setModalUpdate(true)
        setModalShow(true)
    }

    const showModal = () => {
        reset()
        setModalShow(true)
    }
    const hideModal = () => {
        setModalShow(false)
        setModalUpdate(false)
        setDisableFields(false)
    }

    const [addLead] = useMutation(ADD_LEAD);
    const [upsertLead] = useMutation(UPSERT_LEAD)

    const addLeadData = (data) => {
        console.log(data)
        data.lid = uuidv4()
        data.uid = {link: localStorage.getItem("uid")}
        data.date = moment().toISOString()
        data.updatedDate = moment().toISOString()
        data.timestamp = moment().valueOf()
        data.assignedTo = {link: data.assignedTo.uid}
        data.courseEnq = {link: data.courseEnq.cid}
        data.course = null
        data.payment = []
        addLead({
            variables: {
                data: data
            }
        })
        .then(() => {
            message.success('Lead Added Successfully');
            hideModal()
            refetch({
                query: {
                    active: true,
                    // uid: localStorage.getItem("uid")
                }
            })
            .then((res) => {
                window.location.reload()
            })
        })
    }
    const updateLeadData = (data) => {
        console.log(data)
        const currentRecord = _.omit(_.find(mainLeads,function(o){return o.lid === data.lid}),['__typename','key','uid'])
        data.updatedDate = moment().toISOString()
        data.assignedTo = {link: data.assignedTo.uid}
        data.uid = {link: localStorage.getItem("uid")}
        data.courseEnq = {link: data.courseEnq.cid}
        data.course = {link: data.course ? data.course.cid : ""}
        const updatedRecord = {...currentRecord,...data}
        upsertLead({
            variables:{
                query: {
                    lid: data.lid
                },
                data: updatedRecord
            }
        })
        .then(() => {
            message.success('Details Updated Successfully');
            hideModal()
            refetch({
                query: {
                    active: true,
                    // uid: localStorage.getItem("uid")
                }
            })
        })
    }

    const [showRangepicker, setShowRangepicker] = useState(false)

    const datePreview = (value) => {
        setShowRangepicker(false)
        switch (value) {
            case "Today":
                setDateRange([moment().startOf('day'),moment().endOf('day')])
                break;
            case "Yesterday":
                setDateRange([moment().subtract(1,'day').startOf('day'),moment().subtract(1,'day').endOf('day')])
                break;
            case "Last 7 Days":
                setDateRange([moment().subtract(7,'days').startOf('day'),moment().endOf('day')])
                break;
            case "This Month":
                setDateRange([moment().startOf('month').startOf('day'),moment().endOf('day')])
                break;
            case "Last Month":
                setDateRange([moment().subtract(1,'month').startOf('month').startOf('day'),moment().subtract(1,'month').endOf('month').endOf('day')])
                break;
            case "Custom":
                setShowRangepicker(true)
                break;
            default:
                setDateRange([moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")])
                break;
        }
    }

    const [dateRange, setDateRange] = useState([moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")]);
    const defferedDateRage = useDeferredValue(dateRange)

    useMemo(() => {
        if(!_.isEmpty(mainLeads)){
            if(!_.isEmpty(defferedDateRage)){
                const [startDate, endDate] = defferedDateRage;
                if(startDate && endDate){
                    const filteredLeads = _.filter(mainLeads,function(o) { return (moment(o.date).isBetween(startDate.startOf('day'),endDate.endOf('day')))  })
                    // refetch({query:{timestamp_gte:moment(startDate).valueOf(),timestamp_lte:moment(endDate).valueOf()}})
                    setCopyLeads(filteredLeads)
                } else {
                    setCopyLeads(mainLeads)
                }
            } else {
                setCopyLeads(mainLeads)
            }
        }
    },[defferedDateRage, mainLeads])

    const [nivoData,setNivoData] = useState([])
    const [nivoLeadStatus,setNivoLeadStatus] = useState([])

    useMemo(() => {
        let nivoDataCreate = []
        let nivoLeadDataCreate = []
        const groupedLeads = _.groupBy(copyLeads,"leadSource")
        const groupedLeadStat = _.groupBy(copyLeads,"leadStat")
        const colors = ['rgb(66, 133, 244)','rgb(102, 51, 204)','rgb(251, 188, 5)','rgb(135, 21, 83)','rgb(234, 67, 53)','rgb(52, 168, 83)','rgb(4, 198, 200)']
        let i = 0
        _.forOwn(groupedLeads,function(value,key){
            const currentObj = {id:key,label:key,value:value.length,color:colors[i]}
            i++
            nivoDataCreate.push(currentObj)
        })
        i = 0
        _.forOwn(groupedLeadStat,function(value,key){
            let currentObj = {}
            if(key === 'Converted'){
                currentObj['id'] = key
                currentObj['label'] = key
                currentObj['value'] = value.length
                currentObj['color'] = colors[5]
            } else{
                if(i === 5) {
                    i++
                }
                currentObj['id'] = key
                currentObj['label'] = key
                currentObj['value'] = value.length
                currentObj['color'] = colors[i]
            }
            i++
            nivoLeadDataCreate.push(currentObj)
        })
        console.log("Lead Source Group ====> ",nivoDataCreate)
        console.log("Lead Status Group ====> ",nivoLeadDataCreate)
        setNivoData(nivoDataCreate)
        setNivoLeadStatus(nivoLeadDataCreate)
    },[copyLeads])

    return <>
            <Space direction="vertical" style={{display: 'flex'}}>
                <section className="mainLead">
                    <Space style={{display: 'flex'}} split={<Divider type="vertical" style={{height:"15em",borderColor:"rgba(0,0,0,0.095)"}} />}>
                        <Space direction="vertical">
                            <Statistic title="Leads" value={copyLeads.length} />
                            <Statistic title="Leads Converted" value={_.size(_.filter(copyLeads,["balance", "0"]))} valueStyle={{color:"rgb(59, 153, 11)"}} />
                            <Statistic title="Leads inprogress" value={_.size(_.filter(copyLeads,(o) => {return o.trainerTouch && o.balance !== "0"}))} valueStyle={{color:"rgb(242, 175, 19)"}} />
                        </Space>
                        {!_.isEmpty(mainLeads) && <>
                        <div style={{height:200,display:"inline-block",width:370}}>
                            <ResponsivePie
                                data={nivoData}
                                margin={{ top: 10, right: 150, bottom: 10, left: 10 }}
                                innerRadius={0.5}
                                padAngle={2}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                tooltip={function(e){return  <div
                                    style={{
                                        padding: 12,
                                        background: '#ffffff',
                                        display:"flex",
                                        alignItems: "center"
                                    }}
                                ><span style={{backgroundColor:e.datum.color,width:15,height:15,display:"inline-block",marginRight:8}}></span>{e.datum.id + "(" + e.datum.value+ ")"}</div>}}
                                enableArcLinkLabels={false}
                                arcLabelsTextColor={({color}) => {
                                    const rgbArr = color.match(/\d+/g).map(Number)
                                    return invert(rgbArr,true)
                                }}
                                // arcLabel={function(e){return e.value+" %"}}
                                colors={{ datum: 'data.color' }}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 25,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                        <div style={{height:200,display:"inline-block",width:370}}>
                            <ResponsivePie
                                data={nivoLeadStatus}
                                margin={{ top: 10, right: 150, bottom: 10, left: 10 }}
                                innerRadius={0}
                                padAngle={0}
                                cornerRadius={0}
                                activeOuterRadiusOffset={8}
                                borderWidth={0}
                                sortByValue={true}
                                tooltip={function(e){return  <div
                                    style={{
                                        padding: 12,
                                        background: '#ffffff',
                                        display:"flex",
                                        alignItems: "center"
                                    }}
                                ><span style={{backgroundColor:e.datum.color,width:15,height:15,display:"inline-block",marginRight:8}}></span>{e.datum.id + "(" + e.datum.value+ ")"}</div>}}
                                enableArcLinkLabels={false}
                                arcLabelsTextColor={({color}) => {
                                    const rgbArr = color.match(/\d+/g).map(Number)
                                    return invert(rgbArr,true)
                                }}
                                // arcLabel={function(e){return e.value+" %"}}
                                colors={{ datum: 'data.color' }}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 25,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                        </>}
                    </Space>
                    <div className="filterWrapper">
                        <Space>
                            <Select style={{width:200}} onChange={datePreview} defaultValue="All Time" suffixIcon={<FieldTimeOutlined />}>
                                <Option value="All Time">All Time</Option>
                                <Option value="Today">Today</Option>
                                <Option value="Yesterday">Yesterday</Option>
                                <Option value="Last 7 Days">Last 7 Days</Option>
                                <Option value="This Month">This Month</Option>
                                <Option value="Last Month">Last Month</Option>
                                <Option value="Custom">Custom</Option>
                            </Select>
                            {showRangepicker ? <RangePicker onChange={(date) => setDateRange(date)} format={"DD-MM-YYYY"} /> : <RangePicker disabled defaultValue={dateRange} value={dateRange} format={"DD-MM-YYYY"} />}
                        </Space>
                    </div>
                    <ConfigProvider renderEmpty={undefined}>
                        <Table columns={columns} dataSource={copyLeads} loading={leadLoading} scroll={{ x:true, y: 280}} pagination={{hideOnSinglePage:true}} rowClassName={(record) => record?.balance === "0" ? "converted" : record?.trainerTouch ? "touched" : ""} />
                    </ConfigProvider>
                    <div className="btnWrapper">
                        <Button onClick={showModal} type="primary">Add Lead</Button>
                    </div>
                </section>
                <Drawer
                    title={modalUpdate ? "Update Lead" : "Add Lead"}
                    onClose={hideModal}
                    open={modalShow}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                    extra={
                        <Space>
                            <Button onClick={hideModal} danger>Cancel</Button>
                            {
                                modalUpdate ? <Button onClick={handleSubmit(updateLeadData)} type="primary">Update</Button> : <Button onClick={handleSubmit(addLeadData)} type="primary">Submit</Button>
                            }
                        </Space>
                    }
                >
                    <form>
                        <div className={`inputHolder${errors.date ? " error" : ""}`}>
                            <Controller
                                name="date"
                                control={control}
                                render={
                                    ({ field }) => <DatePicker defaultValue={moment()} style={{width:"100%", paddingLeft: 15, paddingRight: 15}} format={"DD-MM-YYYY"} value={moment(field.value)} onChange={field.onChange} onBlur={field.onBlur} ref={field.onBlur} name={field.name} disabled />
                                }
                            />
                        </div>
                        <div className={`inputHolder${errors.name ? " error" : ""}`}>
                            <input type="text" placeholder=" " id="name" {...register("name")}/>
                            <label htmlFor="name">Name</label>
                        </div>
                        <div className={`inputHolder${errors.phoneNumber ? " error" : ""}`}>
                            <input type="tel" placeholder=" " id="phoneNumber" {...register("phoneNumber", { required: true, minLength:10,maxLength:12 })} disabled={disableFields}/>
                            <label htmlFor="phoneNumber">Phone Number</label>
                            {errors.phoneNumber && <span className="err">This field is required</span>}
                        </div>
                        <div className={`inputHolder${errors.courseEnq ? " error" : ""}`}>
                            <select id="courseEnq" {...register("courseEnq.cid")}>
                                <option value="" disabled>Select a Course</option>
                                {
                                    courseList && courseList.courses.map((el) => {
                                        return <option key={el.cid} value={el.cid}>{el.courseName}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="courseEnq">Course Enquired</label>
                            {errors.courseEnq && <span className="err">This field is required</span>}
                        </div>
                        <div className={`inputHolder${errors.leadSource ? " error" : ""}`}>
                            {/* <input type="text" placeholder=" " id="leadSource" {...register("leadSource", { required: true })}/> */}
                            <select id="leadSource" {...register("leadSource", { required: true })}>
                                <option value="" disabled>Select a Lead Source</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Direct Call">Direct Call</option>
                            </select>
                            <label htmlFor="leadSource">Lead Source</label>
                            {errors.leadSource && <span className="err">This field is required</span>}
                        </div>
                        <div className={`inputHolder${errors.leadStat ? " error" : ""}`}>
                            <select id="leadStat" {...register("leadStat", { required: true })} disabled={disableFields}>
                                <option value="" disabled>Select a Lead Status</option>
                                <option value="Not Connected">Not Connected</option>
                                <option value="Not Interested">Not Interested</option>
                                <option value="Interested">Interested</option>
                                <option value="Converted">Converted</option>
                                <option value="Busy">Busy</option>
                            </select>
                            <label htmlFor="leadStat">Lead Status</label>
                            {errors.leadStat && <span className="err">This field is required</span>}
                        </div>
                        <div className={`inputHolder${errors.leadType ? " error" : ""}`}>
                            <select id="leadType" {...register("leadType", { required: true })}>
                                <option value="" disabled>Select a Lead Type</option>
                                <option value="Cold">Cold</option>
                                <option value="Warm">Warm</option>
                                <option value="Hot">Hot</option>
                                <option value="N/A">N/A</option>
                            </select>
                            <label htmlFor="leadType">Lead Type</label>
                            {errors.leadType && <span className="err">This field is required</span>}
                        </div>

                        {/* <div className={`inputHolder${errors.remark ? " error" : ""}`}>
                            <textarea id="remark" placeholder=" " {...register("remark")}></textarea>
                            <label htmlFor="remark">Remark</label>
                            {errors.remark && <span className="err">This field is required</span>}
                        </div> */}
                        <div className={`inputHolder${errors.assignedTo ? " error" : ""}`}>
                            <select id="assignedTo" {...register("assignedTo.uid")} disabled={disableFields}>
                                {
                                    userList && userList.users.map((el) => {
                                        return <option key={el.uid} value={el.uid}>{el.userName}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="assignedTo">Assign to</label>
                            {errors.assignedTo && <span className="err">This field is required</span>}
                        </div>
                    </form>
                </Drawer>
            </Space>
        
        {/* <section className="mainLead open">
            <aside className="siteSidebar">
                <div className="topWrapper">
                </div>
                <div className="bottomWrapper">
                </div>
            </aside>
            <div className="container">
                <div className="filterWrapper">
                    
                    <div className="inputHolder">
                        <DatePicker selectsRange={true} startDate={startDate} endDate={endDate} onChange={(update) => { setDateRange(update) }} isClearable={true} />
                    </div>
                </div>
                <table className="mb25">
                    <thead>
                        <tr>
                            {
                                column.map((el, index) => {
                                    return <th key={index}>{el.name}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" />
                            </td>
                            <td>
                                <input type="text" />
                            </td>
                            <td>
                                <input type="text" />
                            </td>
                            <td>
                                <input type="text" />
                            </td>
                            <td>
                            <select>
                            {
                                userList && userList.users.map((el) => {
                                    return <option key={el.uid} value={el.uid}>{el.userName}</option>
                                })
                            }
                        </select>
                            </td>
                        </tr>
                        {leadLoading && <tr>
                            <td colSpan={5}>Loading</td>    
                        </tr>}
                        {
                            leads && leads.mainLeads.map((el) => {
                                return <tr key={el._id}>
                                    {
                                        column.map((elc,index) => {
                                            if(elc.key === "assignedTo"){
                                                return <td key={index}>{el.assignedTo.userName}</td>
                                            }
                                            if(elc.key === "date"){
                                                return <td key={index}>{moment(el.date).format("DD-MM-YYYY")}</td>
                                            }
                                            return <td key={index}>{el[elc.key]}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <ul className="listTable">
                    <li className="head">
                        {
                            columns.map((el, index) => {
                                return <div key={index} onClick={() => {tableSort(el,index)}} className={el.sort ? `hasSort ${el.asc ? "asc" : "desc"}` : ""}>
                                    {el.name}
                                    {
                                        el.sort && el.isSorrted && <svg viewBox="0 0 50 81.6"><path d="M28.9 13.5v64.2c0 2.2-1.8 3.9-3.9 3.9s-3.9-1.8-3.9-3.9V13.5L6.7 27.8c-1.5 1.5-4 1.5-5.6 0-1.5-1.5-1.5-4 0-5.6l21.1-21c1.5-1.5 4-1.5 5.6 0l21.1 21.1c1.5 1.5 1.5 4 0 5.6-1.5 1.5-4 1.5-5.6 0L28.9 13.5z"/></svg>
                                    }
                                </div>
                            })
                        }
                    </li>
                    {leadLoading && 
                        <li>Loading</li>
                    }
                    {
                        mainLeads.map((el) => {
                            return <li key={el._id}>
                                {
                                    columns.map((elc,index) => {
                                        if(elc.key === "assignedTo"){
                                            return <div key={index}>{el.assignedTo.userName}</div>
                                        }
                                        if(elc.key === "date"){
                                            return <div key={index}>{moment(el.date).format("DD-MM-YYYY")}</div>
                                        }
                                        return <div key={index}>{el[elc.key]}</div>
                                    })
                                }
                            </li>
                        })
                    }
                </ul>
                
            </div>
        </section> */}
    </>
}

export default MainLead