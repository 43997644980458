import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQuery } from "@apollo/client";
import { ADD_COURSE, ADD_ROLE, CREATE_USER, GET_COURSES, GET_ROLES, GET_USERS, UPSERT_COURSE, UPSERT_ROLE, UPSERT_USER } from "../utils/graphql-operation";
import { Button, Card, Drawer, List, Popconfirm, Select, Space, message } from "antd";
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useEffect, useState } from "react";

import Cryptojs from 'crypto-js/sha256'
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const Settings = () => {

    const { setCurntMenu } = useOutletContext();

    const [modalRoleShow, setModalRoleShow] = useState(false)
    const [modalRoleUpdate, setModalRoleUpdate] = useState(false)
    const [modalUserShow, setModalUserShow] = useState(false)
    const [modalUserUpdate, setModalUserUpdate] = useState(false)
    const [modalCourseShow, setModalCourseShow] = useState(false)
    const [modalCourseUpdate, setModalCourseUpdate] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [deleteRecordDetails, setDeleteRecordDetails] = useState({})

    useEffect(()=>{
        setCurntMenu('5')
    },[setCurntMenu])

    const { register, handleSubmit, formState: { errors }, reset: resetRole, setValue: setRoleValues, control } = useForm({
        defaultValues: {
            createdDate: moment().toISOString(),
            roleName: "",
            rId: "",
            roleAccess: [],
            updatedDate: moment().toISOString()       
        },
    });
    const { register:courseRegister, handleSubmit:courseHandleSubmit, formState: { errors:courseErrors }, reset: resetCourse, setValue: setCourseValues } = useForm({
        defaultValues: {
            createdDate: moment().toISOString(),
            courseName: "",
            cid: "",
            updatedDate: moment().toISOString()
        },
    });
    const {loading:roleLoading,data:roles,refetch:roleRefetch}=useQuery(GET_ROLES,{variables:{query:{}}})
    const {loading:courseLoading,data:courses,refetch:courseRefetch}=useQuery(GET_COURSES,{variables:{query:{}}})
    const {loading: userLoading,data:users,refetch: userRefetch}=useQuery(GET_USERS,{variables:{query:{
        default_ne: true,
        active: true,
    }},pollInterval: 500})

    const [addRole] = useMutation(ADD_ROLE)
    const [upsertRole] = useMutation(UPSERT_ROLE)
    const createRole=(data)=>{
        data.rId=uuidv4()
        console.log(data)
        addRole({
            variables: {
                data: data
            }
        })
        roleRefetch()
    }

    const [addCourse] = useMutation(ADD_COURSE)
    const [upsertCourse] = useMutation(UPSERT_COURSE)
    const [upsertUser] = useMutation(UPSERT_USER)
    const createCourse=(data)=>{
        data.cid=uuidv4()
        console.log(data)
        addCourse({
            variables: {
                data: data
            }
        })
        courseRefetch()
    }

    const showRoleModal = () => {
        resetRole()
        setModalRoleShow(true)
    }
    const showUserModal = () => {
        resetUser()
        setModalUserShow(true)
    }
    const showCourseModal = () => {
        resetCourse()
        setModalCourseShow(true)
    }
    const hideModal = () => {
        setModalRoleShow(false)
        setModalRoleUpdate(false)
        setModalUserShow(false)
        setModalUserUpdate(false)
        setModalCourseShow(false)
        setModalCourseUpdate(false)
    }

    const updateRoleData = (data) => {
        // console.log(data)
        upsertRole({
            variables:{
                query:{
                    rId: data.rId
                },
                data: data
            }
        })
        .then(() => {
            message.success('Details Updated Successfully')
            hideModal()
            roleRefetch()
        })
    }
    
    const updateUserData = (data) => {
        console.log(data)
        data.role = {link: data.role}
        if(resetPassword){
            data.password = Cryptojs(`${data.password}englishBus@2022`).toString()
        }
        delete data.confirmPassword
        console.log(data)
        upsertUser({
            variables:{
                query: {
                    uid: data.uid
                },
                data: data
            }
        })
        .then(() => {
            message.success('User Updated Successfully')
            hideModal()
            userRefetch()
        })
    }

    const updateCourseData = (data) => {
        // console.log(data)
        upsertCourse({
            variables:{
                query: {
                    cid: data.cid
                },
                data: data
            }
        })
        .then(() => {
            message.success('Details Updated Successfully');
            hideModal()
            courseRefetch()
        })
    }

    const showPopconfirm = (item) => {
        setDeleteRecordDetails(item)
    };
    
    const handleOk = () => {

    };

    const getRecord = (type,item) => {
        console.log("item ====> ",item)
        if(type === 'Role'){
            resetRole()
            setModalRoleUpdate(true)
            setModalRoleShow(true)
            setRoleValues("createdDate", item.createdDate)
            setRoleValues("rId", item.rId)
            setRoleValues("roleName", item.roleName)
            setRoleValues("roleAccess", item.roleAccess)
            setRoleValues("updatedDate", moment().toISOString())
        }
        if(type === 'Course'){
            resetCourse()
            setModalCourseUpdate(true)
            setModalCourseShow(true)
            setCourseValues("createdDate", item.createdDate)
            setCourseValues("cid", item.cid)
            setCourseValues("courseName", item.courseName)
            setCourseValues("updatedDate", moment().toISOString())
        }
        if(type === 'User'){
            resetUser()
            setModalUserUpdate(true)
            setModalUserShow(true)
            setUserValue("uid", item.uid)
            setUserValue("userName", item.userName)
            setUserValue("role", item.role.rId)
            setUserValue("updatedDate", moment().toISOString())
        }
    }

    const { register:userRegister, handleSubmit: userHandleSubmit, watch, formState: { errors : userErrors }, reset: resetUser, setValue:setUserValue } = useForm({
        defaultValues: {
            userName: '',
            password: '',
            role:'',
            active: true,
            default: false,
            uid: '',
            createdDate: moment().toISOString()
        },
    });
    const [createUserQuery] = useMutation(CREATE_USER);
    const password = watch("password")
    const createUser = async (data) => {
        console.log("Button Clicked")
        const currentRole = roles.roles.find(el => {return el.rId === data.role})
        if(currentRole.assignedCount === "Tutor"){
            data.assignedCount = 0
        }
        delete data.confirmPassword
        data.password = Cryptojs(`${data.password}englishBus@2022`).toString()
        data.uid = uuidv4()
        data.role = {link: data.role}
        console.log(data)
        createUserQuery({
            variables: {
                data: data
            }
        });
        userRefetch()
        .then( res => {
            setModalUserShow(false)
        })
    }


    return <>
        <Space direction="vertical" style={{display: 'flex'}}>
            <section className="mainLead">
                <Space style={{flexWrap:"wrap"}}>
                    <Card style={{width: 695,}} bodyStyle={{height:275,overflowX:'auto'}} title="Roles" extra={<Button type="primary" onClick={showRoleModal}>Add Role</Button>} >
                        {!roleLoading && 
                        // <List
                        //     size="small"
                        //     dataSource={roles.roles}
                        //     renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('Role',item)} />, <Popconfirm title="Are you sure you want to delete this Role" onConfirm={handleOk}><Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(item)}></Button></Popconfirm>]}>{item.roleName}</List.Item>}
                        // />
                        <List
                            size="small"
                            dataSource={roles.roles}
                            renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('Role',item)} />]}>{item.roleName}</List.Item>}
                        />}
                    </Card>
                    <Card style={{width: 695,}} bodyStyle={{height:275,overflowX:'auto'}} title="Users" extra={<Button type="primary" onClick={showUserModal}>Add User</Button>} >
                        {!userLoading && 
                            // <List
                            //     size="small"
                            //     dataSource={users.users}
                            //     renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('User',item)} />, <Popconfirm title="Are you sure you want to delete this User" onConfirm={handleOk}><Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(item)}></Button></Popconfirm>]}>{item.userName}</List.Item>}
                            // ></List>
                            <List
                                size="small"
                                dataSource={users.users}
                                renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('User',item)} />]}>{item.userName}</List.Item>}
                            ></List>
                        }
                    </Card>
                    <Card style={{width: 695,}} bodyStyle={{height:275,overflowX:'auto'}} title="Courses" extra={<Button type="primary" onClick={showCourseModal}>Add Course</Button>} >
                        {!courseLoading && 
                            // <List
                            //     size="small"
                            //     dataSource={courses.courses}
                            //     renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('Course',item)} />, <Popconfirm title="Are you sure you want to delete this User" onConfirm={handleOk}><Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(item)}></Button></Popconfirm>]}>{item.courseName}</List.Item>}
                            // ></List>
                            <List
                                size="small"
                                dataSource={courses.courses}
                                renderItem={(item) => <List.Item actions={[<Button type="link" icon={<EditTwoTone />} onClick={() => getRecord('Course',item)} />]}>{item.courseName}</List.Item>}
                            ></List>
                        }
                    </Card>
                </Space>
            </section>
        </Space>
        <Drawer 
            title={modalRoleUpdate ? "Update Role" : "Add Role"}
            onClose={hideModal}
            open={modalRoleShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={hideModal} danger>Cancel</Button>
                    {
                        modalRoleUpdate ? <Button onClick={handleSubmit(updateRoleData)} type="primary">Update</Button> : <Button onClick={handleSubmit(createRole)} type="primary">Submit</Button>
                    }
                </Space>
            }
        >
            <form>
                <div className={`inputHolder${errors.userName ? " error" : ""}`}>
                    <input type="text" id="role_name" placeholder=" " {...register("roleName",{required:true})}/>
                    <label htmlFor="role_name">Role Name</label>
                </div>
                <div className={`inputHolder select${errors.userName ? " error" : ""}`}>
                    <Controller
                        name="roleAccess"
                        control={control}
                        render={
                            ({ field }) =>  <>
                                <Select mode="tags" style={{ width: '100%' }} size="large" tokenSeparators={[',']} id="roleAccess" placeholder="Select Role Access Points" value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.onBlur} name={field.name}>
                                    <Option value="main-lead">Main Lead</Option>
                                    <Option value="trainer">Trainer</Option>
                                    <Option value="voice-test">Voice Test</Option>
                                </Select>
                                <label htmlFor="roleAccess">Role Access Points</label>
                            </>
                        }
                    />
                </div>
            </form>
        </Drawer>
        <Drawer
            title={modalUserUpdate ? "Update User" : "Create User"}
            onClose={hideModal}
            open={modalUserShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={hideModal} danger>Cancel</Button>
                    {
                        modalUserUpdate ? <Button onClick={userHandleSubmit(updateUserData)} type="primary">Update</Button> : <Button onClick={userHandleSubmit(createUser)} type="primary">Submit</Button>
                    }
                </Space>
            }
        >
            <form>
                <div className={`inputHolder${userErrors.userName ? " error" : ""}`}>
                    <input type="text" id="username" placeholder=' ' {...userRegister("userName", { required: true })}/>
                    <label htmlFor='userName'>Username</label>
                    {userErrors.userName && <span className='err'>This field is required</span>}
                </div>
                {
                    !modalUserUpdate && <>
                        <div className={`inputHolder${userErrors.password ? " error" : ""}`}>
                            <input type="password" id="password" placeholder=' ' {...userRegister("password", { required: true })}/>
                            <label htmlFor='password'>Password</label>
                            {userErrors.password && <span className='err'>This field is required</span>}
                        </div>
                        <div className={`inputHolder${userErrors.confirmPassword ? " error" : ""}`}>
                            <input type="password" id="confirmPassword" placeholder=' ' {...userRegister("confirmPassword", { validate: {required: (value) => value.length > 0 || "This field is required",confirmPassword: (value) =>  value === password || "The passwords do not match" }})}/>
                            <label htmlFor='confirmPassword'>Confirm Password</label>
                            {userErrors.confirmPassword && <span className='err'>{userErrors.confirmPassword.message}</span>}
                        </div>
                    </>
                }
                <div className={`inputHolder${userErrors.role ? " error" : ""}`}>
                    <select id="role" {...userRegister("role")} defaultValue="">
                        <option value="" disabled>Select Role</option>
                        {
                            roles && roles.roles.map(el => {
                                return <option key={el.rId} value={el.rId}>{el.roleName}</option>        
                            })
                        }
                        {/* <option value="Data Entry">Data Entry</option>
                        <option value="Tutor">Tutor</option>
                        <option value="Specialist">Specialist</option> */}
                    </select>
                    <label htmlFor='role'>User Role</label>
                </div>
                {
                    modalUserUpdate && <>
                        <div className="inputHolder">
                            <Button onClick={() => setResetPassword(true)} type="primary">Reset Password</Button>
                        </div>
                        {
                            resetPassword && <>
                                <div className={`inputHolder${userErrors.password ? " error" : ""}`}>
                                    <input type="password" id="password" placeholder=' ' {...userRegister("password", { required: true })}/>
                                    <label htmlFor='password'>Password</label>
                                    {userErrors.password && <span className='err'>This field is required</span>}
                                </div>
                                <div className={`inputHolder${userErrors.confirmPassword ? " error" : ""}`}>
                                    <input type="password" id="confirmPassword" placeholder=' ' {...userRegister("confirmPassword", { validate: {required: (value) => value.length > 0 || "This field is required",confirmPassword: (value) =>  value === password || "The passwords do not match" }})}/>
                                    <label htmlFor='confirmPassword'>Confirm Password</label>
                                    {userErrors.confirmPassword && <span className='err'>{userErrors.confirmPassword.message}</span>}
                                </div>
                            </>
                        }
                    </>
                }
            </form>
        </Drawer>
        <Drawer
            title={modalCourseUpdate ? "Update Course" : "Create Course"}
            onClose={hideModal}
            open={modalCourseShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={hideModal} danger>Cancel</Button>
                    {
                        modalCourseUpdate ? <Button onClick={courseHandleSubmit(updateCourseData)} type="primary">Update</Button> : <Button onClick={courseHandleSubmit(createCourse)} type="primary">Submit</Button>
                    }
                </Space>
            }
        >
            <form>
                <div className={`inputHolder${courseErrors.courseName ? " error" : ""}`}>
                    <input type="text" id="courseName" placeholder=" " {...courseRegister("courseName",{required:true})}/>
                    <label htmlFor="courseName">Coursse Name</label>
                </div>
            </form>
        </Drawer>
    </>
}

export default Settings;
