import { Button, ConfigProvider, Drawer, Popconfirm, Space, Statistic, Table, Radio, Divider, Popover, message, DatePicker, Tabs } from "antd"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { DeleteTwoTone, EditTwoTone, InfoCircleTwoTone } from '@ant-design/icons';

import _ from "lodash"
import {v4 as uuidv4} from "uuid"
import { Controller, useForm } from "react-hook-form";
import { ADD_LEAD, GET_COURSES, GET_LEADS, UPSERT_LEAD } from "../utils/graphql-operation";
import { useMutation, useQuery } from "@apollo/client";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import invert from "invert-color";
import { useOutletContext } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const Trainers = () => {

    const { setCurntMenu } = useOutletContext();

    const [modalShow, setModalShow] = useState(false)
    const [modalUpdate, setModalUpdate] = useState(false)
    const [columns, setColumns] = useState(
        [
            {
                title:"Date",
                dataIndex: "date",
                key: "date",
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
                sortDirections: ['descend'],
                render: (_, record) => {
                    return moment(record.date).format("DD-MM-YYYY")
                },
                width: 150
            },
            {
                title:"Name",
                dataIndex: "name",
                key: "name",
                width: 250,
            },
            {
                title:"Gender",
                dataIndex: "gender",
                key: "gender",
                filters: [
                    {text: "Male", value: "Male"},
                    {text: "Female", value: "Female"},
                ],
                onFilter: (value, record) => record.gender.indexOf(value) === 0,
                width: 100,
            },
            {
                title:"Phone Number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
                filters: [],
                filterSearch: true,
                onFilter: (value, record) => record.phoneNumber.indexOf(value) === 0,
                width: 150,
            },
            {
                title:"Course",
                dataIndex: "course",
                key: "course",
                width: 150,
                render: (_, record) => {
                    return record.course?.courseName
                }
            },
            {
                title:"Batch",
                dataIndex: "batch",
                key: "batch",
                width: 80,
            },
            {
                title:"Payment",
                dataIndex: "payment",
                key: "payment",
                render: (_, record) => {
                    return record.payment.length > 0 ? <Space>{record.payment.reduce((acc,crnt) => { return {value:parseInt(acc.value,10) + parseInt(crnt.value,10)} }).value}<Popover content={<ul className="paymentList">{record.payment.map((el,index) => { return <li key={index}>{el.value}</li>})}</ul>} title="Split Up"><InfoCircleTwoTone /></Popover></Space> : "No Payment Done"
                },
                width: 150,
            },
            {
                title:"Fee",
                dataIndex: "fees",
                key: "fees",
                width: 90,
            },
            {
                title:"Balance",
                dataIndex: "balance",
                key: "balance",
                width: 150,
            },
            // {
            //     title:"Assigned To",
            //     dataIndex: "assignedTo",
            //     key: "assignedTo",
            //     width: 250,
            //     filters: [],
            //     filterSearch: true,
            //     onFilter: (value, record) => record.assignedTo.uid.indexOf(value) === 0,
            //     render: (_, record) => {
            //         return record.assignedTo.userName
            //     }
            // },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="link" icon={<EditTwoTone />} onClick={() => getRecord(record)} />
                        {/* <Popconfirm title="Are you sure you want to delete this lead" onConfirm={handleOk}>
                            <Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(record)}></Button>
                        </Popconfirm> */}
                    </Space>
                ),
            },
        ]
    )
    const [altColumn,setAltColumn] = useState([
        {
            title:"Date",
            dataIndex: "date",
            key: "date",
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
            sortDirections: ['descend'],
            render: (_, record) => {
                return moment(record.date).format("DD-MM-YYYY")
            },
            width: 150
        },
        {
            title:"Name",
            dataIndex: "name",
            key: "name",
            width: 250,
        },
        {
            title:"Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            filters: [],
            filterSearch: true,
            onFilter: (value, record) => record.phoneNumber.indexOf(value) === 0,
            width: 150,
        },
        {
            title:"Voice Test Level",
            dataIndex: "voiceTestLevel",
            key: "voiceTestLevel",
            filters: [
                {value:"Below Average",text:"Below Average"},
                {value:"Average",text:"Average"},
                {value:"Expert",text:"Expert"}
            ],
            width: 180,
        },
        {
            title:"Voice Test Remark",
            dataIndex: "voiceTestRemark",
            key: "voiceTestRemark",
            width: 350,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" icon={<EditTwoTone />} onClick={() => getRecord(record)} />
                    {/* <Popconfirm title="Are you sure you want to delete this lead" onConfirm={handleOk}>
                        <Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(record)}></Button>
                    </Popconfirm> */}
                </Space>
            ),
        },
    ])
    const [mainLeads, setMainLeads] = useState([])
    const [copyLeads, setCopyLeads] = useState([])

    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({
        defaultValues: {
            date: moment().toISOString(),
            name: "",
            phoneNumber: "",
            leadSource: "YouTube",
            assignedTo: "",
            timestamp: moment().valueOf(),
            uid: localStorage.getItem("uid"),
            lid: '',
            active: true,
            gender: "",
            nri: "",
            payment: [],
            balance: "",
            fees:"",
            course:"",
            batch:"",
            courseEnq:"",
            callStat:"",
            leadStat:"Not Connected",
            leadType:"N/A",
            remark:"",
            voiceTestLevel:"",
            voiceTestRemark:""
        },
    })

    const {data: leads, loading:fullLeadLoading, refetch} = useQuery(GET_LEADS,{
        variables: {
            query: {
                active: true,
                leadStat: "Converted",
                AND:[
                    {
                        uid: localStorage.getItem("isAdmin") === "true" ? {uid_nin: ["",null]} : localStorage.getItem("uid")
                    },
                    {
                        uid: {active_ne: false}
                    }
                ]
            }
        },
        pollInterval: 500,
    })
    const {data: courses, loading:coursesLoading, refetch:coursesRefetch} = useQuery(GET_COURSES,{
        variables: {
            query: {
                // active: true,
            }
        }
    })

    useEffect(()=>{
        setCurntMenu('2')
        if(leads){
            const mainLeadsKey = leads.mainLeads.map((el,index) => {
                return {...el, key: (index + 1)}
            })
            setMainLeads(mainLeadsKey)
            setCopyLeads(mainLeadsKey)
            setColumns(
                c => {
                    const fieldIndex = c.findIndex(el => { return el.key === "phoneNumber" })
                    let filter = []
                    leads.mainLeads.forEach(el => filter.push({text: el.phoneNumber, value: el.phoneNumber}))
                    const columnToUpdate = [...c]
                    columnToUpdate[fieldIndex].filters = _.uniqWith(filter,_.isEqual)
                    return columnToUpdate
                }
            )
            setAltColumn(
                ac => {
                    let altFilter = []
                    _.forEach(_.filter(leads.mainLeads,el => {return !_.isEmpty(el.voiceTestAssignedTo)}),els => {
                        altFilter.push({text: els.phoneNumber, value: els.phoneNumber})
                    })
                    const columnUpdated = [...ac]
                    columnUpdated[2].filters = _.uniqWith(altFilter,_.isEqual)
                    return columnUpdated
                }
            )
        }
    },[leads,setCurntMenu])

    const [voiceTest,setVoiceTest] = useState(false)
    const [paymentAdded,setPaymentAdded] = useState(false)

    const getRecord = (record) => {
        console.log("record  ====> ", record)
        setVoiceTest(false)
        setPaymentAdded(false)
        if(!_.isNull(record.voiceTestAssignedTo)){
            setVoiceTest(true)
        }
        if(!_.isEmpty(record.payment)){
            setPaymentAdded(true)
        }
        reset()
        setValue("date", moment(record.date))
        setValue("name", record.name)
        setValue("phoneNumber", record.phoneNumber)
        setValue("leadSource", record.leadSource)
        setValue("assignedTo.uid", record.assignedTo.uid)
        setValue("lid", record.lid)
        setValue("batch", record.batch)
        setValue("fees", record.fees)
        setValue("course.cid", record.course ? record.course.cid : "")
        setValue("courseEnq.cid",record.courseEnq ? record.courseEnq.cid : "")
        setValue("callStat",record.callStat)
        setValue("leadStat",record.leadStat)
        setValue("leadType",record.leadType)
        setValue("remark",record.remark)
        setValue("payment",record.payment)
        setValue("gender", record.gender)
        setValue("nri", record.nri)
        setValue("voiceTestLevel", record.voiceTestLevel)
        setValue("voiceTestRemark", record.voiceTestRemark)
        console.log("Control ====> ",control)
        setModalUpdate(true)
        setModalShow(true)
    }
    const handleOk = () => {}
    const showPopconfirm = () => {}

    // const showModal = () => {
    //     reset()
    //     setModalShow(true)
    // }
    const hideModal = () => {
        setModalShow(false)
        setModalUpdate(false)
    }

    const [addLead] = useMutation(ADD_LEAD);
    const [upsertLead] = useMutation(UPSERT_LEAD)

    const addLeadData = (data) => {
        data.lid = uuidv4()
        data.uid = {link: localStorage.getItem("uid")}
        data.date = moment().toISOString()
        data.updatedDate = moment().toISOString()
        data.timestamp = moment().valueOf()
        data.trainerTouch = true
        data.course = {link: data.course.cid}
        data.voiceTestAssignedTo = {link: data.voiceTestAssignedTo.uid}
        // data.assignedTo = {link: data.assignedTo.uid}
        // data.courseEnq = {link: data.courseEnq.cid}
        // data.payment = []
        addLead({
            variables: {
                data: data
            }
        })
        .then(() => {
            message.success('Lead Added Successfully');
            hideModal()
            refetch({
                query: {
                    active: true,
                    leadStat: "Converted"
                    // uid: localStorage.getItem("uid")
                }
            })
            .then((res) => {
                // console.log(res)
                const addingKey = res.data.mainLeads.map((el,index) => {
                    return {...el, key: (index + 1)}
                })
                setMainLeads(addingKey)
                setCopyLeads(addingKey)
                // window.location.reload()
            })
        })
    }
    const updateLeadData = (data) => {
        const currentRecord = _.omit(_.find(mainLeads,function(o){return o.lid === data.lid}),['__typename','key','uid'])
        console.log("Updating Data ===> ",data)
        data.updatedDate = moment().toISOString()
        data.trainerTouch = true
        data.assignedTo = {link: data.assignedTo.uid}
        data.course = {link: data.course.cid}
        data.uid = {link: localStorage.getItem("uid")}
        data.payment = currentRecord.payment ? _.map(currentRecord.payment, el => {return _.omit(el,['__typename'])}) : []
        data.courseEnq = {link: data.courseEnq.cid}
        data.voiceTestAssignedTo = {link: currentRecord.voiceTestAssignedTo ? currentRecord.voiceTestAssignedTo.uid : null}
        data.balance = currentRecord.balance ? currentRecord.balance : data.fees
        const updatedRecord = {...currentRecord,...data}
        console.log("updatedRecord ===> ",updatedRecord)
        upsertLead({
            variables:{
                query: {
                    lid: data.lid
                },
                data: updatedRecord
            }
        })
        .then(() => {
            message.success('Details Updated Successfully');
            hideModal()
            refetch({
                query: {
                    active: true,
                    leadStat: "Converted"
                    // uid: localStorage.getItem("uid")
                }
            })
            .then((res) => {
                // console.log(res)
                const updatingKey = res.data.mainLeads.map((el,index) => {
                    return {...el, key: (index + 1)}
                })
                setMainLeads(updatingKey)
                setCopyLeads(updatingKey)
                // window.location.reload()
            })
        })
    }

    const [nivoData,setNivoData] = useState([])

    useMemo(() => {
        let nivoDataCreate = []
        const groupedLeads = _.omit(_.groupBy(copyLeads,"course.courseName"),['undefined'])
        const colors = ['rgb(66, 133, 244)','rgb(102, 51, 204)','rgb(251, 188, 5)','rgb(135, 21, 83)','rgb(234, 67, 53)','rgb(52, 168, 83)','rgb(4, 198, 200)']
        let i = 0
        _.forOwn(groupedLeads,function(value,key){
            const currentObj = {id:key,label:key,value:value.length,color:colors[i]}
            i++
            nivoDataCreate.push(currentObj)
        })
        setNivoData(nivoDataCreate)
    },[copyLeads])

    const [nivoLineData,setNivoLineData] = useState([])
    const [nivoLineDivisions,setNivoLineDivisions] = useState(0)

    useMemo(() => {
        let nivoDataCreate = []
        const groupedLeads = _.groupBy(copyLeads,"assignedTo.userName")
        const colors = ['rgb(66, 133, 244)','rgb(102, 51, 204)','rgb(251, 188, 5)','rgb(135, 21, 83)','rgb(234, 67, 53)','rgb(52, 168, 83)','rgb(4, 198, 200)']
        let i = 0
        let max = 0
        _.forOwn(groupedLeads,function(value,key){
            let currentObj = {id:key,color:colors[i],data:[]}
            i++
            const dateGroup = _.groupBy(_.map(value,(el) => {
                return {...el, date:moment(el.date).format("YYYY-MM-DD")}
            }),'date')
            _.forOwn(dateGroup, function(val,k){
                if(max < val.length){
                    max = val.length
                }
                currentObj.data.push({x:val[0].date,y:val.length})
            })
            nivoDataCreate.push(currentObj)
        })
        console.log("Line Data ===> ",nivoDataCreate)
        setNivoLineData(nivoDataCreate)
        setNivoLineDivisions(max)
    },[copyLeads])

    const tabbedContent = [
        {
            key: '1',
            label: `Accounts`,
            children: <ConfigProvider renderEmpty={undefined}><Table columns={columns} dataSource={_.filter(copyLeads,el => {return _.isEmpty(el.voiceTestAssignedTo)})} loading={fullLeadLoading} scroll={{ x:true, y: 300}} pagination={{hideOnSinglePage:true}} rowClassName={(record) => record?.balance === "0" ? "converted" : record?.trainerTouch ? "touched" : ""} /></ConfigProvider>,
        },
        {
            key: '2',
            label: `Voice Test`,
            children: <ConfigProvider renderEmpty={undefined}><Table columns={altColumn} dataSource={_.filter(copyLeads,el => {return !_.isEmpty(el.voiceTestAssignedTo)})} loading={fullLeadLoading} scroll={{ x:true, y: 300}} pagination={{hideOnSinglePage:true}} rowClassName={(record) => record?.balance === "0" ? "converted" : record?.trainerTouch ? "touched" : ""} /></ConfigProvider>,
        }
    ]

    const tabChangeTrigger = (key) => {
        console.log(key)
    }


    return <>
        <Space direction="vertical" style={{display: 'flex'}}>
            <section className="mainLead">
                <Space style={{display: 'flex'}} split={<Divider type="vertical" style={{height:"15em",borderColor:"rgba(0,0,0,0.095)"}} />}>
                    <Space direction="vertical" style={{width:150}}>
                        <Statistic title="Total Leads" value={copyLeads.length} />
                        <Statistic title="Leads To Start" value={copyLeads.filter((el) => {
                                return _.isEmpty(el.balance)
                            }).length
                        } />
                    </Space>
                    <Space direction="vertical" style={{width:150}}>
                        <Statistic title="Leads Inprogress" valueStyle={{color:"rgb(242, 175, 19)"}} value={copyLeads.filter((el) => {
                                return !_.isEmpty(el.balance) && el.balance !== "0"
                            }).length
                        } />
                        <Statistic title="Leads Completed" valueStyle={{color:"rgb(59, 153, 11)"}} value={copyLeads.filter((el) => {
                                return !_.isEmpty(el.balance) && el.balance === "0"
                            }).length
                        } />
                    </Space>
                    <div style={{height:200,display:"inline-block",width:370}}>
                            <ResponsivePie
                                data={nivoData}
                                margin={{ top: 10, right: 150, bottom: 10, left: 10 }}
                                innerRadius={0.5}
                                padAngle={2}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                tooltip={function(e){return  <div
                                    style={{
                                        padding: 12,
                                        background: '#ffffff',
                                        display:"flex",
                                        alignItems: "center"
                                    }}
                                ><span style={{backgroundColor:e.datum.color,width:15,height:15,display:"inline-block",marginRight:8}}></span>{e.datum.id + "(" + e.datum.value+ ")"}</div>}}
                                enableArcLinkLabels={false}
                                arcLabelsTextColor={({color}) => {
                                    const rgbArr = color.match(/\d+/g).map(Number)
                                    return invert(rgbArr,true)
                                }}
                                // arcLabel={function(e){return e.value+" %"}}
                                colors={{ datum: 'data.color' }}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 25,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                        {localStorage.getItem("isAdmin") === "true" &&
                    <div style={{height:200,display:"inline-block",width:"calc(100vw - 1000px)"}}>
                        <ResponsiveLine 
                            data={nivoLineData}
                            margin={{ top: 30, right: 150, bottom: 30, left: 30 }}
                            xScale={{
                                type: 'time',
                                format: '%Y-%m-%d',
                                useUTC: false,
                                precision: 'day',
                            }}
                            xFormat="time:%Y-%m-%d"
                            yScale={{
                                type: 'linear',
                                min: '0',
                                max: nivoLineDivisions + 1,
                                stacked: false,
                                reverse: false
                            }}
                            yFormat=" >(0.2f"
                            axisTop={null}
                            axisRight={null}
                            pointSize={8}
                            enableSlices={"x"}
                            enableGridY={false}
                            axisBottom={{
                                orient: 'right',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: '%b %Y',
                                tickValues: 'every month',
                                legend: 'Date',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 10,
                                tickPadding: 5,
                                tickRotation: 0,
                                tickValues: nivoLineDivisions + 1,
                                legend: 'Count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            useMesh={true}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 110,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            sliceTooltip={({ slice }) => {
                                return (
                                    <div className="tooltipWrap" style={{background: 'white', padding: '9px 12px', border: '1px solid #ccc'}}>
                                        <div className="headWrap"><b>Date: </b>{moment(slice.points[0].data.x).format("DD MMM, YYYY")}</div>
                                        {slice.points.map(point => (
                                        <div key={point.id} style={{ padding: '3px 0'}} >
                                            <span style={{backgroundColor: point.serieColor,borderRadius:"50%",display:"inline-block",height:"10px",marginRight:5,width:"10px"}}></span><b>{point.serieId}</b> - <b>{point.data.y}</b> leads
                                        </div>
                                    ))}
                                    </div>
                                )
                            }}
                        />
                    </div>
                }
                </Space>
                <div className="filterWrapper">
                </div>
                <Tabs defaultActiveKey="1" items={tabbedContent} onChange={tabChangeTrigger} />
                {/* <ConfigProvider renderEmpty={undefined}>
                    <Table columns={columns} dataSource={copyLeads} loading={fullLeadLoading} scroll={{ x:true, y: 300}} pagination={{hideOnSinglePage:true}} rowClassName={(record) => record?.balance === "0" ? "converted" : record?.trainerTouch ? "touched" : ""} />
                </ConfigProvider> */}
                {/* <div className="btnWrapper">
                    <Button onClick={showModal} type="primary">Add Lead</Button>
                </div> */}
            </section>
            <Drawer
                title={modalUpdate ? "Update Lead" : "Add Lead"}
                onClose={hideModal}
                open={modalShow}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={hideModal} danger>Cancel</Button>
                        {
                            modalUpdate ? <Button onClick={handleSubmit(updateLeadData)} type="primary">Update</Button> : <Button onClick={handleSubmit(addLeadData)} type="primary">Submit</Button>
                        }
                    </Space>
                }
            >
                <form>
                    <div className={`inputHolder${errors.date ? " error" : ""}`}>
                        <Controller
                            name="date"
                            control={control}
                            render={
                                ({ field }) => <DatePicker defaultValue={moment()} style={{width:"100%", paddingLeft: 15, paddingRight: 15}} format={"DD-MM-YYYY"} value={moment(field.value)} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} name={field.name} disabled/>
                            }
                        />
                    </div>
                    <div className={`inputHolder${errors.name ? " error" : ""}`}>
                        <input type="text" placeholder=" " id="name" {...register("name")}/>
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="inputHolder radioGroup">
                        <Space>
                            <Controller
                                name="gender"
                                control={control}
                                render={
                                    ({ field }) => <Radio.Group defaultValue={"Male"} buttonStyle="solid" value={field.value} onChange={field.onChange} ref={field.ref} name={field.name}>
                                        <Radio.Button value="Male">Male</Radio.Button>
                                        <Radio.Button value="Female">Female</Radio.Button>
                                    </Radio.Group>
                                }
                            />
                            {/* <Controller
                                    name="nri"
                                    control={control}
                                    render={
                                        ({ field }) => 
                                        <Radio.Group defaultValue={true} buttonStyle="solid" value={field.value} onChange={field.onChange} ref={field.ref} name={field.name}>
                                            <Radio.Button value={true} {...register('nri')}>NRI</Radio.Button>
                                            <Radio.Button value={false} {...register('nri')}>Non-NRI</Radio.Button>
                                        </Radio.Group>
                                    }
                            /> */}
                        </Space>
                    </div>
                    <div className={`inputHolder${errors.phoneNumber ? " error" : ""}`}>
                        <input type="text" placeholder=" " id="phoneNumber" {...register("phoneNumber", { required: true, minLength:10,maxLength:12 })}/>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        {errors.phoneNumber && <span className="err">This field is required</span>}
                    </div>
                    <div className={`inputHolder${errors.course ? " error" : ""}`}>
                        <select id="course" {...register("course.cid")}>
                            <option value={""} disabled>Select a Course</option>
                            {courses && courses.courses.map(el => {
                                return <option key={el.cid} value={el.cid}>{el.courseName}</option>
                            })}
                        </select>
                        <label htmlFor="course">Course</label>
                    </div>
                    <div className={`inputHolder${errors.batch ? " error" : ""}`}>
                        <input type="text" placeholder=" " id="batch" {...register("batch")}/>
                        <label htmlFor="batch">Batch</label>
                    </div>
                    <div className={`inputHolder${errors.fees ? " error" : ""}`}>
                        <input type="text" placeholder=" " id="fees" {...register("fees")} disabled={paymentAdded}/>
                        <label htmlFor="fees">Fees</label>
                    </div>
                    {
                        voiceTest && <>
                            <div className="inputHolder">
                                <b>Voice Test Status</b>
                            </div>
                            <div className={`inputHolder${errors.voiceTestLevel ? " error" : ""}`}>
                                <select id="voiceTestLevel" {...register("voiceTestLevel", { required: true })}>
                                    <option value="" disabled>Select a Lead Status</option>
                                    <option value="Below Average">Below Average</option>
                                    <option value="Average">Average</option>
                                    <option value="Expert">Expert</option>
                                </select>
                                <label htmlFor="voiceTestLevel">Level</label>
                            </div>
                            <div className="inputHolder">
                                <textarea placeholder=" " id="voiceTestRemark" {...register('voiceTestRemark')}></textarea>
                                <label htmlFor="voiceTestRemark">Remark</label>
                            </div>
                        </>
                    }
                </form>
            </Drawer>
        </Space>
    </>
}

export default Trainers