import './App.css';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Image, Layout, Menu, Space } from 'antd';
import { UserOutlined, TeamOutlined, SettingOutlined, CreditCardOutlined } from '@ant-design/icons';

import {USER_LOGIN} from "./utils/graphql-operation"

import SiteHeader from './components/header';

import logo from "./logo-white.png"
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

const { Header, Sider, Content } = Layout;

function App() {
  const [curntMenu,setCurntMenu] = useState('1')
  const navigate = useNavigate();
  const location = useLocation()

  const [navigation, setNavigation] = useState([
    {
      key: '1',
      icon: <UserOutlined />,
      label: (
        <Link to={'/main-lead'} >Leads</Link>
      ),
    },
    {
      key: '2',
      icon: <TeamOutlined />,
      label: (
        <Link to={'/student-counsellor'}>Student Counsellor</Link>
      ),
    }
  ]) 

  const [
    getUser
  ] = useLazyQuery(USER_LOGIN, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('uid')
    if(!isLoggedIn){
      navigate("/login")
    } else {
      getUser({
        variables: {
          query: {
            uid: isLoggedIn,
            active: true
          }
        }
      })
      .then(res => {
        if(_.isEmpty(res.data.user)){
          localStorage.removeItem('uid')
          navigate("/login")
        } else {
          const userDetails = res.data.user
          if(userDetails.default){
            localStorage.setItem("isAdmin",true)
            if(_.size(navigation)<3){
              const adminNav = [...navigation,{
                key: '4',
                icon: <CreditCardOutlined />,
                label: (
                  <Link to={'/payments'}>Payments</Link>
                ),
              },
              {
                key: '5',
                icon: <SettingOutlined />,
                label: (
                  <Link to={'/settings'}>Settings</Link>
                ),
              }]
              setNavigation(adminNav)
            }
            if(location.pathname === "/"){
              navigate("/main-lead")
            }
          } else {
            if(location.pathname === "/"){
              navigate(`/${userDetails.role.roleAccess[0]}`)
            }
          }
          // console.log(res.data.user)
        }
      })
    }
  },[navigate])


  return (
    <div className="App">
      <Layout>
        <Sider collapsible collapsed={true} trigger={null}>
          <Space direction="vertical" style={{padding:'10px',display: 'flex', alignItems: 'center'}} size="large">
            <Image width={45} src={logo} preview={false} />
            <Menu
              theme="dark"
              mode="inline"
              style={{width: '80px'}}
              items={navigation}
              selectedKeys={[curntMenu]}
            />
          </Space>
        </Sider>
          <Layout>
            <Header className="site-layout-background">
                <SiteHeader />
            </Header>
            <Content>
              <Outlet context={{setCurntMenu}} />
            </Content>
            </Layout>
      </Layout>
      </div>
  );
}

export default App;
