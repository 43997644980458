import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import * as Realm from "realm-web";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/home';
import MainLead from './components/mainLead';
import Settings from './components/settings';
import Login from './components/login';
import Trainers from './components/trainers';
import Payments from './components/payment';

export const APP_ID = "application-0-nqsbb";


const app = new Realm.App(APP_ID);

async function getValidAccessToken() {
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.emailPassword("crm@englishbus.in","crmGetPassed@2022"));
  } else {
    await app.currentUser.refreshCustomData();
  }
  return app.currentUser.accessToken;
}

const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://asia-south1.gcp.realm.mongodb.com/api/client/v2.0/app/application-0-nqsbb/graphql`,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="main-lead" element={<MainLead />} />
          <Route path="settings" element={<Settings />} />
          <Route path="student-counsellor" element={<Trainers />} />
          <Route path="payments" element={<Payments />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ApolloProvider>
);
