// import { Link } from 'react-router-dom'
import { Button, } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';

// const OverlayMenu = () => {
//     return (
//         <Menu
//             items={[
//                 {
//                     label: (
//                         <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
//                         Profile
//                         </a>
//                     ),
//                     key: '0'
//                 },
//                 {
//                     type: 'divider'
//                 },
//                 {
//                     label: (
//                         <Button type="link" size="small">Logout</Button>
//                     ),
//                     key: '1'
//                 },
//             ]}
//         />
//     )
// };

const SiteHeader = () => {
    const navigate = useNavigate();
    return <header className='siteHeader'>
        <div className='dFlex alignCenter'>
            <div className='mla'>
                {/* <Dropdown overlay={OverlayMenu}>
                    <button onClick={(e) => e.preventDefault()}>
                        <span className="avatar-item">
                            <Badge count={1}>
                                <Avatar
                                    style={{
                                        backgroundColor: '#87d068',
                                    }}
                                    icon={<UserOutlined />}
                                />
                            </Badge>
                        </span>
                    </button>
                </Dropdown> */}
                <Button type="link" icon={<LogoutOutlined />} size={'small'} onClick={() => {localStorage.removeItem('uid');localStorage.removeItem('isAdmin');navigate("/login")}} >Logout</Button>
            </div>
        </div>
    </header>
}

export default SiteHeader