import gql from "graphql-tag";

export const CREATE_USER = gql`
    mutation createUser($data: UserInsertInput!){
        insertOneUser(data:$data) {
            userName
            uid
            active
            role {
                rId
                roleName
            }
        }
    }
`

export const UPSERT_USER = gql`
    mutation upsertUser($query: UserQueryInput, $data:UserInsertInput!){
        upsertOneUser(query: $query, data: $data) {
            userName
            uid
            active
            role {
                rId
                roleName
            }
        }
    }
`

export const GET_USERS = gql`
    query getUsers($query: UserQueryInput!){
        users(query: $query){
            userName
            uid
            active
            role {
                rId
                roleName
            }
        }
    }
`

export const GET_LEADS = gql`
    query getLeads($query: MainLeadQueryInput,$sort:MainLeadSortByInput){
        mainLeads(query: $query,sortBy: $sort){
            uid{
                uid
                userName
            }
            lid
            date
            leadSource
            name
            phoneNumber
            timestamp
            payment {
                value
                submitting
            }
            course {
                cid
                courseName
            }
            batch
            fees
            balance
            gender
            nri
            assignedTo {
                active
                uid
                userName
            }
            trainerTouch
            courseEnq {
                cid
                courseName
            }
            callStat
            leadStat
            leadType
            remark
            mop
            account
            voiceTestAssignedTo{
                active
                uid
                userName
            }
            voiceTestLevel
            voiceTestRemark
        }
    }
`

export const ADD_LEAD = gql`
    mutation addLead($data: MainLeadInsertInput!){
        insertOneMainLead(data:$data){
            uid{
                uid
                userName
            }
            lid
            date
            leadSource
            name
            phoneNumber
            timestamp
            payment {
                value
                submitting
            }
            course {
                cid
                courseName
            }
            batch
            fees
            balance
            gender
            nri
            assignedTo {
                active
                uid
                userName
            }
            trainerTouch
            courseEnq {
                cid
                courseName
            }
            callStat
            leadStat
            leadType
            remark
            mop
            account
            voiceTestAssignedTo{
                active
                uid
                userName
            }
            voiceTestLevel
            voiceTestRemark
        }
    }
`

export const UPSERT_LEAD = gql`
    mutation upsertLead($query: MainLeadQueryInput, $data:MainLeadInsertInput!){
        upsertOneMainLead(query: $query, data: $data){
            uid{
                uid
                userName
            }
            lid
            date
            leadSource
            name
            phoneNumber
            timestamp
            payment {
                value
                submitting
            }
            course {
                cid
                courseName
            }
            batch
            fees
            balance
            gender
            nri
            assignedTo {
                active
                uid
                userName
            }
            trainerTouch
            courseEnq {
                cid
                courseName
            }
            callStat
            leadStat
            leadType
            remark
            mop
            account
            voiceTestAssignedTo{
                active
                uid
                userName
            }
            voiceTestLevel
            voiceTestRemark
        }
    }
`

export const USER_LOGIN = gql`
    query userLogin($query: UserQueryInput!){
        user(query: $query){
            userName
            uid
            role {
                roleAccess
            }
            default
        }
    }
`

export const ADD_ROLE = gql`
    mutation addRole($data: RoleInsertInput!){
        insertOneRole(data:$data) {
            createdDate
            rId
            roleName
            roleAccess
            updatedDate
        }
    }
`

export const UPSERT_ROLE = gql`
    mutation upsertRole($query: RoleQueryInput, $data: RoleInsertInput!){
        upsertOneRole(query: $query, data: $data) {
            createdDate
            rId
            roleName
            roleAccess
            updatedDate
        }
    }
`

export const GET_ROLES = gql`
    query roles($query: RoleQueryInput!){
        roles(query:$query){
            rId
            roleName
            roleAccess
            updatedDate
        }
    }
`

export const ADD_COURSE = gql`
    mutation addCourse($data: CourseInsertInput!){
        insertOneCourse(data:$data) {
            createdDate
            cid
            courseName
            updatedDate
        }
    }
`

export const GET_COURSES = gql`
    query courses($query: CourseQueryInput!){
        courses(query:$query){
            createdDate
            cid
            courseName
            updatedDate
        }
    }
`

export const UPSERT_COURSE = gql`
    mutation upsertCourse($query: CourseQueryInput, $data:CourseInsertInput!){
        upsertOneCourse(query: $query, data: $data){
            createdDate
            cid
            courseName
            updatedDate
        }
    }
`

export const DOWNLOAD_CSV = gql`
    query downloadcsv($input: Parameter){
        downloadCsv(input:$input) {
            account
            balance
            batch
            callStat
            date
            fees
            gender
            leadSource
            leadStat
            leadType
            mop
            name
            nri
            phoneNumber
            remark
            payment{
                value
                date
            }
            course{
                courseName
            }
            assignedTo{
                userName
            }
            voiceTestLevel
            voiceTestRemark
            voiceTestAssignedTo{
                userName
            }
        }
    }
`