import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment"
import { Button, ConfigProvider, DatePicker, Divider, Drawer, List, message, Popconfirm, Popover, Radio, Select, Slider, Space, Statistic, Table } from "antd"
import { useDeferredValue, useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { DOWNLOAD_CSV, GET_COURSES, GET_LEADS, GET_USERS, UPSERT_LEAD } from "../utils/graphql-operation";
import { DeleteTwoTone, EditTwoTone, InfoCircleTwoTone, FieldTimeOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import _ from "lodash"
import { Controller, useForm } from "react-hook-form";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from '@nivo/bar'
import invert from "invert-color";
import Papa from "papaparse"

const Payments = () => {
    const { setCurntMenu } = useOutletContext();

    const { RangePicker } = DatePicker;
    const { Option } = Select;

    const [mainLeads, setMainLeads] = useState([])
    const [copyLeads, setCopyLeads] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [filterModalShow, setFilterModalShow] = useState(false)
    const [paymentInitial, setPaymentInitial] = useState([])
    const [fees, setFees] = useState("")
    const [voiceTest, setVoiceTest] = useState(false)
    const [deleteRecordDetails, setDeleteRecordDetails] = useState({})
    const [columns, setColumns] = useState(
        [
            {
                title:"Date",
                dataIndex: "date",
                key: "date",
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
                sortDirections: ['descend'],
                render: (_, record) => {
                    return moment(record.date).format("DD-MM-YYYY")
                },
                width: 150
            },
            {
                title:"Name",
                dataIndex: "name",
                key: "name",
                width: 250,
            },
            {
                title:"Phone Number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
                filters: [],
                filterSearch: true,
                onFilter: (value, record) => record.phoneNumber.indexOf(value) === 0,
                width: 150,
            },
            // {
            //     title:"Course",
            //     dataIndex: "course",
            //     key: "course",
            //     width: 150,
            //     render: (_, record) => {
            //         return record.course?.courseName
            //     }
            // },
            // {
            //     title:"Batch",
            //     dataIndex: "batch",
            //     key: "batch",
            //     width: 150,
            // },
            {
                title:"Payment",
                dataIndex: "payment",
                key: "payment",
                render: (_, record) => {
                    return record.payment.length > 0 ? <Space>{record.payment.reduce((acc,crnt) => { return {value:parseInt(acc.value,10) + parseInt(crnt.value,10)} }).value}<Popover content={<ul className="paymentList">{record.payment.map((el,index) => { return <li key={index}>{el.value}</li>})}</ul>} title="Split Up"><InfoCircleTwoTone /></Popover></Space> : "No Payment Done"
                },
                width: 150,
            },
            {
                title:"Account",
                dataIndex: "account",
                key: "account",
                width: 200,
                filters: [
                    {value:"BETTER ENGLISH",text:"BETTER ENGLISH"},
                    {value:"NOUFAL NASSAR",text:"NOUFAL NASSAR"},
                    {value:"ASMA PM",text:"ASMA PM"},
                    {value:"SUHARA NASAR",text:"SUHARA NASAR"},
                    {value:"SANAM NOUFAL",text:"SANAM NOUFAL"}
                ],
                filterSearch: true,
                onFilter: (value, record) => record.account.indexOf(value) === 0,
            },
            {
                title:"Balance",
                dataIndex: "balance",
                key: "balance",
                width: 90,
            },
            {
                title:"Fees",
                dataIndex: "fees",
                key: "fees",
                width: 80,
            },
            {
                title:"NRI",
                dataIndex: "nri",
                key: "nri",
                filters: [
                    {text: "Is NRI", value: true},
                    {text: "Is not NRI", value: false},
                ],
                onFilter: (value, record) => record.nri === value,
                render: (_, record) => {
                    return record.nri ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ff4d4f" />
                },
                width: 90,
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="link" icon={<EditTwoTone />} onClick={() => getRecord(record)} />
                        {/* <Popconfirm title="Are you sure you want to delete this lead" onConfirm={handleOk}>
                            <Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />}  onClick={() => showPopconfirm(record)}></Button>
                        </Popconfirm> */}
                    </Space>
                ),
            },
        ]
    )



    const {data: leads, loading:fullLeadLoading, refetch} = useQuery(GET_LEADS,{
        variables: {
            query: {
                active: true,
                course: {cid_ne:""},
                batch_ne:"",
                fees_ne:""
            }
        }
    })

    const {data: courses, loading:coursesLoading, refetch:coursesRefetch} = useQuery(GET_COURSES,{
        variables: {
            query: {
                // active: true,
            }
        }
    })

    const [
        downloadcsv
    ] = useLazyQuery(DOWNLOAD_CSV, {
        fetchPolicy: 'network-only'
    })

    const {data: userVoiceTest}  = useQuery(GET_USERS,{
        variables: {
            query: {
                default_ne: true,
                active: true,
                role:{roleName: "Voice Test Trainer"}
            }
        }
    })

    useEffect(()=>{
        if(leads){
            setCurntMenu('4')
            const mainLeadsKey = leads.mainLeads.map((el,index) => {
                return {...el, key: (index + 1)}
            })
            setMainLeads(mainLeadsKey)
            setCopyLeads(mainLeadsKey)
            setColumns(
                c => {
                    const fieldIndex = c.findIndex(el => { return el.key === "phoneNumber" })
                    let filter = []
                    leads.mainLeads.forEach(el => filter.push({text: el.phoneNumber, value: el.phoneNumber}))
                    const columnToUpdate = [...c]
                    columnToUpdate[fieldIndex].filters = _.uniqWith(filter,_.isEqual)
                    return columnToUpdate
                }
            )
        }
    },[leads, setCurntMenu])

    const getRecord = (data) => {
        reset()
        setVoiceTest(_.sumBy(data.payment,function(o) { return parseInt(o.value,10); }).toString() === data.fees)
        setPaymentInitial(data.payment)
        setValue('voiceTestAssignedTo.uid',data.voiceTestAssignedTo ? data.voiceTestAssignedTo.uid : "")
        setValue('account',data.account)
        setValue('mop',data.mop)
        setValue('balance',data.balance)
        setValue('lid',data.lid)
        setValue('nri',data.nri)
        setFees(data.fees)
        setModalShow(true)

    }

    const downloadFIlter = () => {
        resetCSV()
        setFilterModalShow(true)
        setValueCSV('paymentBetween',[0,(parseInt((_.sumBy(_.maxBy(copyLeads,function(o){return parseInt(_.sumBy(o.payment,function(s) { return parseInt(s.value,10); }),10)})?.payment,function(t) { return parseInt(t.value,10); })/2)/1000,10) * 1000)])
        setValueCSV("date",[moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")])
        setValueCSV("nri","Both")
        setValueCSV("balance","Both")
    }

    const handleOk = () => {
        console.log(deleteRecordDetails)
        // upsertLead({
        //     variables:{
        //         query: {
        //             lid: deleteRecordDetails.lid
        //         },
        //         data: {...deleteRecordDetails, active: false}
        //     }
        // })
        // .then(() => {
        //     message.success("Lead Deleted Successfully");
        //     refetch({
        //         query: {
        //             active: true,
        //             course: {cid_ne:""}
        //             // uid: localStorage.getItem("uid")
        //         }
        //     })
        //     .then(res => {
        //         console.log(res)
        //         const mainLeadsKey = res.data.mainLeads.map((el,index) => {
        //             return {...el, key: (index + 1)}
        //         })
        //         setMainLeads(mainLeadsKey)
        //         setCopyLeads(mainLeadsKey)
        //     })
        //     setDeleteRecordDetails({})
        //     // setConfirmLoading(false);
        // })
    }
    const showPopconfirm = (record) => {
        console.log(record)
        setDeleteRecordDetails(record)
    }

    const [upsertLead] = useMutation(UPSERT_LEAD)
    const updatePaymentData = (data) => {
        console.log("Payment Data ===> ",data)
        data.voiceTestAssignedTo = {link: data.voiceTestAssignedTo.uid}
        const currentRecord = _.find(mainLeads,['lid',data.lid])
        console.log("currentRecord ====> ",currentRecord)
        let recordToupdate = {...currentRecord,...data}
        recordToupdate.payment = _.map(paymentInitial,(el) => {return {value: el.value,submitting: false, date: el.date}})
        recordToupdate.balance = (parseInt(recordToupdate.fees,10) - _.sumBy(paymentInitial, function(o) {return parseInt(o.value)},10)).toString()
        recordToupdate.assignedTo = {link: recordToupdate.assignedTo.uid}
        recordToupdate.course = {link: currentRecord.course.cid ? currentRecord.course.cid : ""}
        recordToupdate.uid = {link: recordToupdate.uid.uid}
        recordToupdate.courseEnq = {link: recordToupdate.courseEnq ? recordToupdate.courseEnq.cid : ""}
        recordToupdate = _.omit(recordToupdate,['__typename','key'])
        recordToupdate.active = true
        recordToupdate.updatedDate = moment().toISOString()
        upsertLead({
            variables:{
                query: {
                    lid: data.lid
                },
                data: recordToupdate
            }
        })
        .then(() => {
            message.success('Details Updated Successfully');
            hideModal()
            refetch({
                query: {
                    active: true,
                    course: {cid_ne:""}
                    // uid: localStorage.getItem("uid")
                }
            }).then(res => {
                console.log(res)
                const mainLeadsKey = res.data.mainLeads.map((el,index) => {
                    return {...el, key: (index + 1)}
                })
                setMainLeads(mainLeadsKey)
                setCopyLeads(mainLeadsKey)
            })
        })
    }

    const { register, handleSubmit, reset, control, setValue, formState: {errors} } = useForm({
        payment: [],
        balance: "",
        remark:"",
        account: "",
        mop:"",
        paymentComplete: false,
        voiceTestAssignedTo: "",
        nri: "",
        lid: ""
    })

    const {  handleSubmit:handleSubmitCSV, reset:resetCSV, setValue:setValueCSV, control:controlCSV, formState: {errors: errorsCSV} } = useForm({
        paymentBetween: [],
        nri: "Both",
        date:[moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")],
        account: "",
        balance: "Both",
        course:""
    })

    const hideModal = () => {
        setModalShow(false)
        setFilterModalShow(false)
    }

    const [showRangepicker, setShowRangepicker] = useState(false)

    const datePreview = (value) => {
        setShowRangepicker(false)
        switch (value) {
            case "Today":
                setDateRange([moment().startOf('day'),moment().endOf('day')])
                break;
            case "Yesterday":
                setDateRange([moment().subtract(1,'day').startOf('day'),moment().subtract(1,'day').endOf('day')])
                break;
            case "Last 7 Days":
                setDateRange([moment().subtract(7,'days').startOf('day'),moment().endOf('day')])
                break;
            case "This Month":
                setDateRange([moment().startOf('month').startOf('day'),moment().endOf('day')])
                break;
            case "Last Month":
                setDateRange([moment().subtract(1,'month').startOf('month').startOf('day'),moment().subtract(1,'month').endOf('month').endOf('day')])
                break;
            case "Custom":
                setShowRangepicker(true)
                break;
            default:
                setDateRange([moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")])
                break;
        }
    }

    const [dateRange, setDateRange] = useState([moment('2022/08/01', "YYYY/MM/DD"), moment(moment(), "YYYY/MM/DD")]);
    const defferedDateRage = useDeferredValue(dateRange)

    useMemo(() => {
        if(!_.isEmpty(mainLeads)){
            if(!_.isEmpty(defferedDateRage)){
                const [startDate, endDate] = defferedDateRage;
                if(startDate && endDate){
                    const filteredLeads = _.filter(mainLeads,function(o) { return (moment(o.date).isBetween(startDate.startOf('day'),endDate.endOf('day')))  })
                    // refetch({query:{timestamp_gte:moment(startDate).valueOf(),timestamp_lte:moment(endDate).valueOf()}})
                    setCopyLeads(filteredLeads)
                } else {
                    setCopyLeads(mainLeads)
                }
            } else {
                setCopyLeads(mainLeads)
            }
        }
    },[defferedDateRage, mainLeads])

    const [paymentRemove, setPaymentRemove] = useState("")

    const confirmPaymentRemove = (paymentVal) => {
        setPaymentRemove(paymentVal)
    }

    const removePayment = () => {
        const removalArray = [...paymentInitial]
        _.pullAt(removalArray,[_.findIndex(removalArray,{ 'value': paymentRemove, 'submitting': true })])
        console.log(removalArray)
        setPaymentInitial(removalArray)
        setValue("payment",removalArray)
    }

    const [nivoData,setNivoData] = useState([])
    const [nivoBarData,setNivoBarData] = useState([])

    useMemo(() => {
        if(!_.isEmpty(copyLeads)){
            let nivoDataCreate = []
            const payments = _.sum(_.map(copyLeads.reduce((acc,crnt) => {
                const setArr = [...acc.payment, ...crnt.payment]
                return {payment: setArr}
            }).payment,(el) => {return _.parseInt(el.value)}))
            const fees = copyLeads.reduce((acc,crnt) => {
                return {fees: parseInt(acc.fees,10) + parseInt(crnt.fees,10)}
            }).fees
            const currentObj = [{id:"Payments",label:"Payments",value:payments,color:'rgb(135, 21, 83)'},{id:"Balance",label:"Balance",value:(fees - payments),color:'rgb(4, 198, 200)'}]
            // const colors = ['rgb(66, 133, 244)','rgb(102, 51, 204)','rgb(251, 188, 5)','rgb(135, 21, 83)','rgb(234, 67, 53)','rgb(52, 168, 83)','rgb(4, 198, 200)']
            const groupedLeads = _.groupBy(copyLeads,"assignedTo.userName")
            console.log(groupedLeads)
            _.forOwn(groupedLeads,function(value,key){
                let currentObj = {user:key,payments:parseInt(_.sumBy(value,function(o){return parseInt(_.sumBy(o.payment,function(n){return parseInt(n.value,10)}),10)}),10)}
                nivoDataCreate.push(currentObj)
            })
            setNivoData(currentObj)
            setNivoBarData(nivoDataCreate)
        }
    },[copyLeads])

    const filterDownload = (data) => {
        console.log(data)
        downloadcsv({
            variables:{
                input:{
                    account:data.account.join(","),
                    course:data.course.join(","),
                    date:`${moment(data.date[0]).valueOf()},${moment(data.date[1]).valueOf()}`,
                    paymentBetween:`${data.paymentBetween[0]},${data.paymentBetween[1]}`,
                    nri:data.nri,
                    balance:data.balance
                }
            }
        })
        .then(({data}) => {
            if(!_.isEmpty(data.downloadCsv)) {
                const jsonData = data.downloadCsv.map(el => {
                    const csvData = {
                        Date:el.date,
                        Name: el.name,
                        "Phone Number":el.phoneNumber,
                        Gender:el.gender,
                        NRI: el.nri,
                        "Assigned To":el.assignedTo.userName,
                        Course: el.course.courseName,
                        Batch:el.batch,
                        Balance:el.balance,
                        Fees:el.fees,
                        "Payment Status": el.balance === "0" ? "Fully Paid" : el.balance === el.fees ? "Zero Payment" : "Patially Paid",
                        // Payment: _.sumBy(el.payment, function(o) {return parseInt(o.value,10)}),
                        "Payment Remark": el.remark,
                        Account:el.account,
                        "Mode of Payment":el.mop,
                        "Call Status":el.callStat,
                        "Lead Source":el.leadSource,
                        "Lead Status":el.leadStat,
                        "Lead Type":el.leadType,
                        // "Voice Test Level": el.voiceTestLevel,
                        // "Voice Test Evaluator": el.voiceTestAssignedTo.userName,
                        // "Voice Test Remark": el.voiceTestRemark,
                    }
                    if(el.voiceTestAssignedTo){
                        csvData["Voice Test Level"] = el.voiceTestLevel
                        csvData["Voice Test Evaluator"] = el.voiceTestAssignedTo.userName
                        csvData["Voice Test Remark"] = el.voiceTestRemark
                    }
                    if(!_.isEmpty(el.payment)){
                        // console.log(el.payment)
                        for(let i = 0; i < el.payment.length; i++){
                            // console.log(el.name, " => ", i)
                            csvData[`Payment ${i + 1}`] = el.payment[i]["value"]
                            csvData[`Payment ${i + 1} Date`] = el.payment[i]["date"]
                        }
                    }
                    return csvData
                })
                console.log("For CSV download => ",jsonData)
                const csv = Papa.unparse(jsonData,{
                    header: true,
                    quotes: false,
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ",",
                    newline: "\r\n",
                    skipEmptyLines: false,
                    columns: null
                })

                const element = document.createElement("a");
                const file = new Blob([csv], {type: 'text/csv'});
                element.href = URL.createObjectURL(file);
                element.download = `Payment Details - ${moment().format("DD-MM-YYYY HH:mm:ss")}.csv`;
                document.body.appendChild(element);
                element.click();
                element.remove()
            } else {
                message.warning('No Data available for the current filter. Please redefine your filter.');
            }
        })
    }

    return <Space direction="vertical" style={{display: 'flex'}}>
        <section className="mainLead">
            <Space style={{display: 'flex'}} split={<Divider type="vertical" style={{height:"15em",borderColor:"rgba(0,0,0,0.095)"}} />}>
                <Space direction="vertical" style={{width:150}}>
                    <Statistic title="Total Oppurtunities" value={copyLeads.length} />
                    <Statistic title="Ready for Voice Test" valueStyle={{color:"rgb(59, 153, 11)"}} value={copyLeads.filter((el) => {
                            return !_.isEmpty(el.balance) && el.balance === "0"
                        }).length
                    } />
                </Space>
                <div style={{height:200,display:"inline-block",width:370}}>
                    <ResponsivePie
                        data={nivoData}
                        margin={{ top: 10, right: 150, bottom: 10, left: 10 }}
                        innerRadius={0}
                        padAngle={0}
                        cornerRadius={0}
                        activeOuterRadiusOffset={8}
                        borderWidth={0}
                        sortByValue={false}
                        tooltip={function(e){return  <div
                            style={{
                                padding: 12,
                                background: '#ffffff',
                                display:"flex",
                                alignItems: "center"
                            }}
                        ><span style={{backgroundColor:e.datum.color,width:15,height:15,display:"inline-block",marginRight:8}}></span>{e.datum.id + "(" + e.datum.value+ ")"}</div>}}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor={({color}) => {
                            const rgbArr = color.match(/\d+/g).map(Number)
                            return invert(rgbArr,true)
                        }}
                        // arcLabel={function(e){return e.value+" %"}}
                        colors={{ datum: 'data.color' }}
                        legends={[
                            {
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 25,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                <div style={{height:200,display:"inline-block",width:450}}>
                    <ResponsiveBar
                        data={nivoBarData}
                        indexBy="user"
                        keys={['payments']}
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Student Counsellor',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Amount',
                            legendPosition: 'middle',
                            tickValues: 5,
                            legendOffset: -50
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        legends={[]}
                    />
                </div>
            </Space>
            <div className="filterWrapper">
                <Space>
                    <Button type="primary" onClick={() => downloadFIlter()}>Download CSV</Button>
                    <Select style={{width:200}} onChange={datePreview} defaultValue="All Time" suffixIcon={<FieldTimeOutlined />}>
                        <Option value="All Time">All Time</Option>
                        <Option value="Today">Today</Option>
                        <Option value="Yesterday">Yesterday</Option>
                        <Option value="Last 7 Days">Last 7 Days</Option>
                        <Option value="This Month">This Month</Option>
                        <Option value="Last Month">Last Month</Option>
                        <Option value="Custom">Custom</Option>
                    </Select>
                    {showRangepicker ? <RangePicker onChange={(date) => setDateRange(date)} format={"DD-MM-YYYY"} /> : <RangePicker disabled defaultValue={dateRange} value={dateRange} format={"DD-MM-YYYY"} />}
                </Space>
            </div>
            <ConfigProvider renderEmpty={undefined}>
                <Table columns={columns} dataSource={copyLeads} loading={fullLeadLoading} scroll={{ x:true, y: 300}} pagination={{hideOnSinglePage:true}} rowClassName={(record) => record?.balance === "0" ? "converted" : ""} />
            </ConfigProvider>
        </section>
        <Drawer
            title="Update Payment"
            onClose={hideModal}
            open={modalShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={hideModal} danger>Cancel</Button>
                    {
                        <Button onClick={handleSubmit(updatePaymentData)} type="primary">Submit</Button>
                    }
                </Space>
            }
        >
            <form>
                <div className="inputHolder paymentWrapper">
                    <List dataSource={paymentInitial} footer={<div className="footerWrap"><div className="fees">Fees: <b>{fees}</b></div><div className="total">Total: <b>{paymentInitial && paymentInitial.length > 0 ? paymentInitial.reduce((acc,crnt) => { return {value:parseInt(acc.value,10) + parseInt(crnt.value,10)} }).value : 0}</b></div></div>} renderItem={(item) => (
                        <List.Item actions={[item.submitting ? <Popconfirm title="Are you sure you want to delete this Payment" onConfirm={removePayment}>
                        <Button type="link" danger icon={<DeleteTwoTone twoToneColor="#f50f50"  />} onClick={() => confirmPaymentRemove(item.value)}></Button>
                    </Popconfirm> : ""]}>
                            {item.value}
                        </List.Item>
                    )}>
                    </List>
                    {/* {
                        paymentInitial && paymentInitial.length > 0 ? paymentInitial.map((_el,index) => {
                            return <Tag key={index} closable={true} onClose={removePayment}>{_el}</Tag>
                        }) : ""
                    }
                    <span className="total">Total: <b>{paymentInitial && paymentInitial.length > 0 ? paymentInitial.reduce((acc,crnt) => { return parseInt(acc,10) + parseInt(crnt,10) }) : 0}</b></span> */}
                </div>
                <div className="inputHolder">
                    <input type="number" id="payment" onKeyDown={(e) => {
                        if (!_.includes(["Backspace", '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], e.key))
                        {
                            e.preventDefault();
                        }
                        if(e.key === "Enter"){
                            e.preventDefault()
                            if(parseInt(_.sumBy(paymentInitial,function(o) { return parseInt(o.value,10); }),10) <= parseInt(fees,10)){
                                console.log("Enter")
                                let  paymentEntering = e.target.value
                                if(paymentEntering.charAt(0) === "."){
                                    paymentEntering = `0${paymentEntering}`
                                }
                                const paymentEntered = parseInt(_.sumBy(paymentInitial,function(o) { return parseInt(o.value,10); }),10) + parseInt(paymentEntering,10)
                                if(paymentEntered > parseInt(fees,10)){
                                    const deduction = paymentEntered - parseInt(fees,10)
                                    const voicePayments = parseInt(paymentEntered,10) - deduction
                                    paymentEntering = (parseInt(paymentEntering,10) - deduction).toString()
                                    if(parseInt(voicePayments,10) === parseInt(fees,10)){
                                        console.log("Voice test")
                                        setVoiceTest(true)
                                    }
                                }
                                setPaymentInitial([{value:paymentEntering,submitting:true,date:moment().toISOString()},...paymentInitial])
                                setValue("payment",paymentInitial)
                                e.target.value = null
                            }
                        }
                        return false
                    }} placeholder=" " disabled={parseInt(_.sumBy(paymentInitial,function(o) { return parseInt(o.value,10); }),10) === parseInt(fees,10) ? true : false}/>
                    <label htmlFor="payment">Payment</label>
                </div>
                <div className="inputHolder">
                    <select id="accountSelection" {...register('account')}>
                        <option value="BETTER ENGLISH">BETTER ENGLISH</option>
                        <option value="NOUFAL NASSAR">NOUFAL NASSAR</option>
                        <option value="ASMA PM">ASMA PM</option>
                        <option value="SUHARA NASAR">SUHARA NASAR</option>
                        <option value="SANAM NOUFAL">SANAM NOUFAL</option>
                    </select>
                    <label htmlFor="accountSelection">Account</label>
                </div>
                <div className={`inputHolder${errors.nri ? " error" : ""}`}>
                    <Controller
                        name="nri"
                        control={control}
                        render={
                            ({ field }) => <Radio.Group defaultValue={false} buttonStyle="solid" value={field.value} onChange={field.onChange} ref={field.ref} name={field.name}>
                                <Radio.Button value={true}>NRI</Radio.Button>
                                <Radio.Button value={false}>Non-NRI</Radio.Button>
                            </Radio.Group>
                        }
                    />
                </div>
                <div className="inputHolder">
                    <textarea placeholder=" " id="paymentMethod" {...register('mop')}></textarea>
                    <label htmlFor="paymentMethod">Mode Of Payment</label>
                </div>
                <div className="inputHolder">
                    <textarea placeholder=" " id="paymentRemark" {...register('remark')}></textarea>
                    <label htmlFor="paymentRemark">Remark</label>
                </div>
                {
                    (voiceTest && userVoiceTest) && <div className="inputHolder">
                        <select id="voiceTestAdsign" {...register('voiceTestAssignedTo.uid')}>
                            {
                                userVoiceTest.users.map(el => {
                                    return <option key={el.uid} value={el.uid}>{el.userName}</option>
                                })
                            }
                        </select>
                        <label htmlFor="voiceTestAdsign">Assign Voice Test to</label>
                    </div>
                }
                <input type="hidden" {...register('lid')} />
            </form>
        </Drawer>
        <Drawer
            title="Download CSV"
            onClose={hideModal}
            open={filterModalShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={hideModal} danger>Cancel</Button>
                    {
                        <Button onClick={handleSubmitCSV(filterDownload)} type="primary">Submit</Button>
                    }
                </Space>
            }
        >
            <form>
                <div className={`inputHolder${errors.date ? " error" : ""}`}>
                    <Controller
                        name="date"
                        control={controlCSV}
                        render={
                            ({ field }) => <RangePicker defaultValue={dateRange} style={{width:"100%", paddingLeft: 15, paddingRight: 15}} format={"DD-MM-YYYY"} allowClear={false} value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} name={field.name}/>
                        }
                    />
                </div>
                <div className={`inputHolder${errors.nri ? " error" : ""}`}>
                    <Controller
                        name="nri"
                        control={controlCSV}
                        render={
                            ({ field }) => <>{console.log("NRI => ", field)}<Radio.Group defaultValue={"Both"} buttonStyle="solid" value={field.value} onChange={field.onChange} ref={field.ref} name={field.name}>
                            <Radio.Button value="Both">Both</Radio.Button>
                            <Radio.Button value="true">NRI</Radio.Button>
                            <Radio.Button value="false">Non-NRI</Radio.Button>
                        </Radio.Group></>
                        }
                    />
                </div>
                <div className={`inputHolder${errors.balance ? " error" : ""}`}>
                    <Controller
                        name="balance"
                        control={controlCSV}
                        render={
                            ({ field }) => <Radio.Group defaultValue={"Both"} buttonStyle="solid" value={field.value} onChange={field.onChange} ref={field.ref} name={field.name}>
                            <Radio.Button value="Both">Both</Radio.Button>
                            <Radio.Button value="true">Fully Paid</Radio.Button>
                            <Radio.Button value="false">Partially Paid</Radio.Button>
                        </Radio.Group>
                        }
                    />
                </div>
                <div className={`inputHolder select${errorsCSV.account ? " error" : ""}`}>
                    <Controller
                        name="account"
                        control={controlCSV}
                        rules={{ required: true }}
                        render={
                            ({ field }) => <>
                                <Select mode="multiple" placeholder="Select an Account" id="accountSelection" style={{ width: '100%' }} tokenSeparators={[',']} value={field.value} onChange={field.onChange} ref={field.ref} name={field.name} options={[{value:"BETTER ENGLISH",label:"BETTER ENGLISH"},{value:"NOUFAL NASSAR",label:"NOUFAL NASSAR"},{value:"ASMA PM",label:"ASMA PM"},{value:"SUHARA NASAR",label:"SUHARA NASAR"},{value:"SANAM NOUFAL",label:"SANAM NOUFAL"}]} />
                                <label htmlFor="accountSelection">Account</label>
                            </>
                        }
                    />
                    {errorsCSV.account && <span className="err">This field is required</span>}
                </div>
                <div className={`inputHolder select${errors.course ? " error" : ""}`}>
                    <Controller
                            name="course"
                            control={controlCSV}
                            rules={{ required: true }}
                            render={
                                ({ field }) => <>
                                    <Select mode="multiple" placeholder="Select an Course" id="courseSelection" style={{ width: '100%' }} tokenSeparators={[',']} value={field.value} onChange={field.onChange} ref={field.ref} name={field.name} options={_.map(courses.courses,el => {return {value:el.cid,label:el.courseName}})} />
                                    <label htmlFor="courseSelection">Course</label>
                                </>
                            }
                        />
                        {/* <select id="course" {...register("course.cid")}>
                            <option value={""} disabled selected>Select a Course</option>
                            {courses && courses.courses.map(el => {
                                return <option key={el.cid} value={el.cid}>{el.courseName}</option>
                            })}
                        </select> */}
                        {/* <label htmlFor="course">Course</label> */}
                    </div>
                <div className="inputHolder">
                    <label>Payment between</label>
                    <Controller
                        name="paymentBetween"
                        control={controlCSV}
                        render={
                            ({ field }) => <Slider defaultValue={[0, (parseInt((_.sumBy(_.maxBy(copyLeads,function(o){return parseInt(_.sumBy(o.payment,function(s) { return parseInt(s.value,10); }),10)})?.payment,function(t) { return parseInt(t.value,10); })/2)/1000,10) * 1000)]} handleStyle={{backgroundColor:"#1890ff"}} max={_.sumBy(_.maxBy(copyLeads,function(o){return parseInt(_.sumBy(o.payment,function(s) { return parseInt(s.value,10); }),10)})?.payment,function(t) { return parseInt(t.value,10); })} range={true} value={field.value} onChange={field.onChange} ref={field.ref} name={field.name} />
                        }
                        />
                </div>
            </form>
        </Drawer>
    </Space>
}

export default Payments