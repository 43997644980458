import { useLazyQuery } from "@apollo/client"
import { useForm } from "react-hook-form"
import logo from '../logo.png'
import { USER_LOGIN } from "../utils/graphql-operation"
import Cryptojs from 'crypto-js/sha256'
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { message } from "antd"

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const uid = localStorage.getItem('uid')
        if(uid){
            navigate('/')
        }
    },[navigate])

    const {register, handleSubmit, formState: { errors }} = useForm({
        userName:'',
        password:''
    })

    const [
        getUser
    ] = useLazyQuery(USER_LOGIN, {
        fetchPolicy: 'network-only'
    })

    const login = async (data) => {
        data.password = Cryptojs(`${data.password}englishBus@2022`).toString()
        // console.log(data)
        await getUser({ variables: { query: { userName: data.userName, password: data.password } }})
        .then((res) => {
            if(res.data.user === null){
                message.error("Username or Password provided is wrong");
            } else {
                localStorage.setItem("uid",`${res.data.user.uid}`)
                if(res.data.user.default){
                    navigate("/main-lead")
                    return
                }
                navigate(`/${res.data.user.role.roleAccess[0]}`)
            }
        })
        .catch((err)=>{
            console.log("Error after Login",err)
            message.error("Something went wrong. Please try again after sometime");
        })
    }

    return <div className="loginWrapper">
        <div className="loginBox">
            <figure className="logo mb25 dFlex">
                <img src={logo} alt="English bus" />
                <p>English <span>Bus</span></p>
            </figure>
            <form onSubmit={handleSubmit(login)}>
                <div className={`inputHolder${errors.userName ? " error" : ""}`}>
                    <input type="text" id="userName" placeholder=" " {...register('userName',{required: true})} />
                    <label htmlFor="userName">Username</label>
                    {errors.userName && <span className="err">Please enter the Username</span>}
                </div>
                <div className={`inputHolder${errors.userName ? " error" : ""}`}>
                    <input type="password" id="password" placeholder=" " {...register('password',{required: true})} />
                    <label htmlFor="password">Password</label>
                    {errors.userName && <span className="err">Please enter the Password</span>}
                </div>
                <div className="inputHolder">
                    <button type="submit" className="btn">Login</button>
                </div>
            </form>
        </div>
    </div>
}

export default Login